import { createEnum } from "../utils/enum-util";

export const AdGoalMetric = createEnum({
    IMPRESSION: {
        name: "Impressions",
    },
    BUDGET: {
        name: "Budget",
    },
});
