import { useQuery } from "react-query";

import { api } from "../api";

export function useUnreadMessageCount(permitted) {
    const enabled = permitted ?? true;

    return useQuery({
        queryKey: ["unread-message-count"],
        queryFn: () => api.messages.count().then((res) => res.data),
        //refetchInterval: 60 * 1000,
        //refetchIntervalInBackground: 5 * 60 * 1000,
        refetchOnMount: false,
        enabled: enabled,
    });
}
