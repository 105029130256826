import { useEffect, useState } from "react";
import { useOnlineStatus } from "@beachfront/ui/hooks";
import { useToast } from "@beachfront/ui";

export function useOfflineNotification() {
    const [wasOffline, setWasOffline] = useState(false);
    const onlineStatus = useOnlineStatus();
    const toast = useToast();
    const key = "offline";

    useEffect(() => {
        if (!onlineStatus && !wasOffline) {
            toast.error({
                key,
                title: "You are currently offline.",
                duration: Infinity,
            });
            setWasOffline(true);
        } else if (onlineStatus && wasOffline) {
            toast.success({
                key,
                title: "You are connected.",
                duration: 3000,
            });
            setWasOffline(false);
        }
    }, [onlineStatus, wasOffline]);
}
