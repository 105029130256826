import { createEnum } from "../utils/enum-util";

export const TrafficRule = createEnum({
    NONE: {
        name: "None",
    },
    INCLUDE: {
        name: "Include",
    },
    EXCLUDE: {
        name: "Exclude",
    },
});
