import { useLocalStorage } from "@beachfront/ui/hooks";

import { useCurrentUser } from "./use-current-user";

/**
 * Uses column config state from local storage.
 *
 * @param   {String} storageKey
 * @param   {Object} [options]
 * @param   {*}      [options.initialState]
 * @returns {[*, Function]}
 */
export function useColumnConfig(storageKey, options = {}) {
    const { initialState } = options;

    const [user] = useCurrentUser();
    const key = `${storageKey}-${user.usersId}`;
    const [data, setData] = useLocalStorage(key, { value: initialState, timestamp: Date.now() });

    const columnConfig = data.value;
    const setColumnConfig = (value) => setData({ value, timestamp: Date.now() });

    return [columnConfig, setColumnConfig];
}
