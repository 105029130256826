import mobileWebIcon from "../assets/img/inventory-platforms/bf-mobile-web.png";
import desktopWebIcon from "../assets/img/inventory-platforms/bf-desktop-web.png";
import androidPhoneIcon from "../assets/img/inventory-platforms/bf-android-phone.png";
import androidTabletIcon from "../assets/img/inventory-platforms/bf-android-tablet.png";
import iphoneIcon from "../assets/img/inventory-platforms/bf-apple-iphone.png";
import ipadIcon from "../assets/img/inventory-platforms/bf-apple-ipad.png";
import googleTvIcon from "../assets/img/inventory-platforms/bf-google-tv.png";
import fireTvIcon from "../assets/img/inventory-platforms/bf-fire-tv.png";
import appleTvIcon from "../assets/img/inventory-platforms/bf-apple-tv.png";
import rokuTvIcon from "../assets/img/inventory-platforms/bf-roku-tv.png";
import arrisTvIcon from "../assets/img/inventory-platforms/bf-arris-tv.png";
import { createEnum } from "../utils/enum-util";

export const InventoryPlatform = createEnum({
    MOB_WEB: {
        name: "Mobile Web",
        icon: mobileWebIcon,
    },
    DESKTOP_WEB: {
        name: "Desktop Web",
        icon: desktopWebIcon,
    },
    ANDR_PHONE: {
        name: "Android Phone",
        icon: androidPhoneIcon,
    },
    ANDR_TABLET: {
        name: "Android Tablet",
        icon: androidTabletIcon,
    },
    IPHONE: {
        name: "iPhone",
        icon: iphoneIcon,
    },
    IPAD: {
        name: "iPad",
        icon: ipadIcon,
    },
    WIN8: {
        name: "Windows 8",
    },
    TV_GOOGLE: {
        name: "Google TV",
        icon: googleTvIcon,
    },
    TV_FIRE: {
        name: "Fire TV",
        icon: fireTvIcon,
    },
    TV_APPLE: {
        name: "Apple TV",
        icon: appleTvIcon,
    },
    TV_ROKU: {
        name: "Roku",
        icon: rokuTvIcon,
    },
    TV_ARRIS: {
        name: "Arris",
        icon: arrisTvIcon,
    },
    TV_SAMSUNG: {
        name: "Samsung TV",
    },
    TV_LG: {
        name: "LG",
    },
    TV_PANASONIC: {
        name: "Panasonic",
    },
    TV_CISCO: {
        name: "Cisco",
    },
    TV_PLAYSTATION: {
        name: "Playstation",
    },
    TV_XBOX: {
        name: "Xbox",
    },
    TV_VIZIO: {
        name: "Vizio TV",
    },
    TV_OPERA: {
        name: "Opera TV",
    },
    TV_CHROMVECAST: {
        name: "Chromecast",
    },
    STB: {
        name: "STB",
    },
    TV_STB: {
        name: "Other STB Devices",
    },
    TV_HISENSE: {
        name: "Hisense TV",
    },
    TV_SONY: {
        name: "Sony TV",
    },
});
