import {
    HomeOutlined,
    PieChartOutlined,
    GemOutlined,
    DatabaseOutlined,
    TagsOutlined,
    SwapOutlined,
    ApartmentOutlined,
    ShopOutlined,
    SolutionOutlined,
    ShoppingOutlined,
    MonitorOutlined,
    MailOutlined,
    ReadOutlined,
    SettingOutlined,
    QuestionCircleOutlined,
    CrownOutlined,
} from "@beachfront/ui/icons";

import Permission from "../../components/permission";
import { AccountType, AccountSubType, CustomPermission } from "../../enums";

export default [
    {
        icon: <HomeOutlined />,
        title: "Overview",
        href: "/overview",
    },
    {
        icon: <PieChartOutlined />,
        title: "Dashboard",
        children: [
            {
                title: "Inventory",
                href: "/dashboard/inventory",
                access: "dashboard.inventory.read",
            },
            {
                title: "Podding",
                href: "/dashboard/podding",
                access: (user) =>
                    Permission.hasAccess(user, CustomPermission.PODDING.key) &&
                    Permission.hasAccess(user, "dashboard.inventory.read"),
            },
            {
                title: "Ads",
                href: "/dashboard/ads",
                access: "dashboard.demand.read",
            },
            {
                title: "Advertisers",
                href: "/dashboard/advertisers",
                access: (user) =>
                    Permission.hasAccess(user, "dashboard.demand.read") &&
                    (user.account_type === AccountType.SUPER.key ||
                        user.account_sub_type === AccountSubType.ENTERPRISE_PUB_DIRECT.key),
            },
            {
                title: "Campaigns",
                href: "/dashboard/campaigns",
                access: "dashboard.demand.read",
            },
            {
                title: "Creatives",
                href: "/dashboard/creatives",
                access: "dashboard.demand.read",
            },
            {
                title: "Publishers",
                href: "/dashboard/publishers",
                access: (user) =>
                    Permission.hasAccess(user, "publishers.manage_accounts.read") &&
                    !user.isOrganizationAdmin,
            },
            {
                title: "Accounts",
                href: "/dashboard/accounts",
                access: (user) =>
                    user.isOrganizationAdmin ||
                    Permission.hasAccess(user, "dashboard.account.read"),
            },
        ],
    },
    {
        icon: <GemOutlined />,
        title: "Quality",
        children: [
            {
                title: "Ad Library",
                href: "/quality/ad-library",
                access: CustomPermission.QUALITY_BUYERS.key,
            },
            {
                title: "Advertisers",
                href: "/quality/advertisers",
                access: CustomPermission.CREATIVEREVIEW.key,
            },
            {
                title: "Advertiser Domains",
                href: "/quality/advertiserdomains",
                access: AccountType.SUPER.key,
            },
            {
                title: "Buyers",
                href: "/quality/buyers",
                access: CustomPermission.QUALITY_BUYERS.key,
            },
            {
                title: "Rules",
                href: "/quality/rules",
                access: CustomPermission.CREATIVEREVIEW.key,
            },
            {
                title: "Creative Review",
                href: "/quality/creative-review",
                access: CustomPermission.CREATIVEREVIEW.key,
            },
            {
                title: "Ads.txt Verified",
                href: "/quality/adstxt",
                access: CustomPermission.CREATIVEREVIEW.key,
            },
        ],
    },
    {
        icon: <DatabaseOutlined />,
        title: "Demand",
        children: [
            {
                title: "Buyers",
                href: "/demand/buyers",
                access: (user) =>
                    Permission.hasAccess(user, "demand.dsp.read") && !user.isOrganizationAdmin,
            },
            {
                title: "DSP",
                href: "/demand/dsp",
                access: CustomPermission.DSP.key,
            },
            {
                title: "Campaigns",
                href: "/demand/campaigns",
                access: "demand.campaigns.read",
            },
            {
                title: "Ads",
                href: "/demand/ads",
                access: "demand.ads.read",
            },
            {
                title: "Deals",
                href: "/demand/deals",
                access: (user) =>
                    Permission.hasAccess(user, "custom.deals.read") &&
                    Permission.hasAccess(user, "demand.ads.read"),
            },
            {
                title: "Creatives",
                href: "/demand/creatives",
                access: "demand.creatives.read",
            },
            {
                title: "Lists",
                href: "/demand/lists",
                access: "demand.media_lists.read",
            },
            {
                title: "Keys",
                href: "/demand/keys",
                access: "demand.keys.read",
            },
            {
                title: "Advertisers",
                href: "/demand/advertisers",
                access: (user) =>
                    Permission.hasAccess(user, "demand.creatives.read") &&
                    Permission.hasAccess(user, AccountType.SUPER.key),
            },
            {
                title: "Audience Segments",
                href: "/demand/audience-segments",
                access: (user) =>
                    Permission.hasAccess(user, CustomPermission.AUDIENCE.key) &&
                    !user.isOrganizationAdmin,
            },
            {
                title: "User Sync",
                href: "/demand/user-sync",
                access: (user) =>
                    Permission.hasAccess(user, "demand.users_sync.read") &&
                    !user.isOrganizationAdmin,
            },
        ],
    },
    {
        icon: <TagsOutlined />,
        title: "Inventory",
        href: "/inventory",
        access: "inventory.manage_inventory.read",
    },
    {
        icon: <SwapOutlined />,
        title: "Exchanges",
        href: "/exchanges",
        access: [AccountType.SUPER.key, AccountType.ENTERPRISE.key],
    },
    {
        icon: <ShoppingOutlined />,
        title: "Marketplaces",
        children: [
            {
                title: "Owned Marketplaces",
                href: "/marketplaces/owned",
                access: "marketplaces.owned.read",
            },
        ],
    },
    {
        icon: <ApartmentOutlined />,
        title: "App Mapping",
        href: "/app-mapping",
        access: "inventory.manage_inventory.read",
    },
    {
        icon: <ShopOutlined />,
        title: "Publishers",
        href: "/publishers",
        access: (user) =>
            Permission.hasAccess(user, "publishers.manage_accounts.read") &&
            !user.isOrganizationAdmin,
    },
    {
        icon: <SolutionOutlined />,
        title: "Reporting",
        children: [
            {
                title: "Canned Reports",
                href: "/reporting/canned",
                access: (user) =>
                    Permission.hasAccess(user, "reporting.report.read") &&
                    !user.isOrganizationAdmin,
            },
            {
                title: "Custom Reports",
                href: "/reporting/custom",
                access: "reporting.report.read",
            },
        ],
    },
    {
        icon: <MonitorOutlined />,
        title: "Monitor",
        href: "/monitor",
        access: CustomPermission.MONITOR.key,
    },
    {
        icon: <MailOutlined />,
        title: "Messages",
        href: "/messages",
        access: CustomPermission.MESSAGES.key,
    },
    {
        icon: <ReadOutlined />,
        title: "Change Log",
        href: "/changelog",
        access: "users.logs.read",
    },
    {
        icon: <SettingOutlined />,
        title: "Settings",
        children: [
            {
                title: "Profile",
                href: "/settings/profile",
                access: "users.settings_profile.edit",
            },
            {
                title: "Password",
                href: "/settings/password",
                access: "users.settings_profile.edit",
            },
            {
                title: "Account",
                href: "/settings/account",
                access: "users.settings_account.read",
            },
            {
                title: "Users",
                href: "/settings/users",
                access: "users.manage_users.read",
            },
            {
                title: "Organization",
                href: "/settings/organization",
                access: CustomPermission.ORGANIZATION.key,
            },
            {
                title: "API",
                href: "/settings/api",
                access: (user) =>
                    Permission.hasAccess(user, "users.settings_api.read") &&
                    !user.isOrganizationAdmin,
            },
            {
                title: "Notifications",
                href: "/settings/notifications",
                access: CustomPermission.NOTIFICATION.key,
            },
        ],
    },
    {
        icon: <QuestionCircleOutlined />,
        title: "Help",
        children: [
            {
                title: "Support",
                href: "https://beachfrontmedia.atlassian.net/servicedesk/customer/portal/2",
            },
            {
                title: "Privacy Policy",
                href: "http://beachfront.com/privacy-policy",
            },
            {
                title: "Terms of Service",
                href: "http://beachfront.com/terms/",
            },
        ],
    },
    {
        icon: <CrownOutlined />,
        title: "Admin",
        href: "https://platform-admin.beachfront.io/",
        access: "admin.dashboard.read",
    },
];
