import { createEnum } from "../utils/enum-util";

export const AudienceSource = createEnum({
    IMPORT: {
        name: "Import",
    },
    UPLOAD: {
        name: "Upload",
    },
    UNKNOWN: {
        name: "Unknown",
    },
});
