import { createEnum } from "../utils/enum-util";

export const AccountListRule = createEnum({
    ALL: {
        key: 2,
        name: "All Accounts",
    },
    INCLUDE: {
        key: 0,
        name: "Include Selected Accounts",
    },
    EXCLUDE: {
        key: 1,
        name: "Exclude Selected Accounts",
    },
});
