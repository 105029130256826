import { createEnum } from "../utils/enum-util";

export const FrequencyCapTimeUnit = createEnum({
    MIN: {
        name: "Minutes",
    },
    HOUR: {
        name: "Hours",
    },
    DAY: {
        name: "Days",
    },
    WEEK: {
        name: "Weeks",
    },
});
