import { createEnum } from "../utils/enum-util";

export const RelationshipType = createEnum({
    DIRECT: {
        name: "Direct",
    },
    RESELLER: {
        name: "Reseller",
    },
});
