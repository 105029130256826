import { useMemo } from "react";
import { useSessionStorage } from "@beachfront/ui/hooks";
import dayjs from "dayjs";

import { isString } from "../utils/type-util";

/**
 * Uses filter state from session storage.
 *
 * @param {String} storageKey
 * @param {Object} [options]
 * @param {*}      [options.initialState]
 * @param {Object} [options.schema]
 * @returns {{
 *     all: Array,
 *     populated: Array,
 *     setFilters: function(Array)
 * }}
 */
export function useFilters(storageKey, options = {}) {
    const { initialState, schema = {} } = options;

    const [allFilters, setFilters] = useSessionStorage(storageKey, (filters) => {
        if (Array.isArray(filters)) {
            return filters.map((el) => {
                const desc = { ...el };

                if (schema[desc.dataIndex] === "date[]") {
                    const [start, end] = desc.value ?? [];
                    desc.value = [dayjs(start), dayjs(end)];
                }

                if (schema[desc.dataIndex] === "date") {
                    desc.value = dayjs(desc.value);
                }

                if (schema[desc.dataIndex] === "unix") {
                    desc.value = dayjs.unix(desc.value);
                }

                return desc;
            });
        }
        return initialState ?? [];
    });

    const populatedFilters = useMemo(() => {
        return allFilters.filter((f) => {
            if (Array.isArray(f.value) || isString(f.value)) {
                return f.value.length > 0;
            }
            return true;
        });
    }, [allFilters]);

    return {
        all: allFilters,
        populated: populatedFilters,
        setFilters,
    };
}
