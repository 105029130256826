import { createEnum } from "../utils/enum-util";

export const MetricType = createEnum({
    COUNT: {
        name: "Count",
    },
    CURRENCY: {
        name: "Currency",
    },
    PERCENT: {
        name: "Percent",
    },
    SECONDS: {
        name: "Seconds",
    },
    MILLISECONDS: {
        name: "Milliseconds",
    },
});
