import { createEnum } from "../utils/enum-util";

export const InventoryStatus = createEnum({
    DISABLED: {
        key: "0",
        name: "Disabled",
    },
    ENABLED: {
        key: "1",
        name: "Enabled",
    },
    PENDING: {
        key: "2",
        name: "Pending",
    },
});
