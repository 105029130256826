import { createEnum } from "../utils/enum-util";

export const QualityRuleAcl = createEnum({
    NONE: {
        name: "No Action",
    },
    INCLUDE: {
        name: "Include",
    },
    EXCLUDE: {
        name: "Exclude",
    },
});
