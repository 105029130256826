import { createEnum } from "../utils/enum-util";

export const EncryptionType = createEnum({
    NONE: {
        name: "None",
    },
    OPENX: {
        name: "Open X",
    },
});
