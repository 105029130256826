import { createEnum } from "../utils/enum-util";

export const SupplyType = createEnum({
    UNKNOWN: {
        name: "Unknown",
    },
    DIRECT: {
        name: "Direct",
    },
    AGGREGATOR: {
        name: "Aggregator",
    },
});
