import { createEnum } from "../utils/enum-util";

export const AdType = createEnum({
    VAST_SERVER_SIDE: {
        key: 2,
        name: "VAST (Server Side)",
    },
    DIRECT_UPLOAD: {
        key: 3,
        name: "Direct Upload",
    },
    VAST_RTB: {
        key: 4,
        name: "VAST RTB",
    },
    VAST_VPAID_CLIENT_SIDE: {
        key: 8,
        name: "VAST / VPAID JS or Flash (Client-Side)",
    },
    VPAID_RTB: {
        key: 9,
        name: "VPAID RTB",
    },
    DEAL: {
        key: 10,
        name: "Deal",
    },
    DISPLAY_RTB: {
        key: 13,
        name: "Display RTB",
    },
    ADI_CREATIVE: {
        key: 14,
        name: "Adi Creative",
    },
});
