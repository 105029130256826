import { createEnum } from "../utils/enum-util";

export const MarketplacePriority = createEnum({
    FIRST: {
        name: "First",
    },
    LAST: {
        name: "Last",
    },
});
