import { Suspense, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Spinner, Modal } from "@beachfront/ui";

import { http } from "../../api";
import RequireAuth from "../require-auth";

import AppLayout from "./app-layout";

function ProtectedLayout() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        Modal.destroyAll();
    }, [location]);

    useEffect(() => {
        const interceptorId = http.interceptors.response.use(
            (res) => res,
            (error) => {
                if (error.response?.status === 401) {
                    navigate("/logout");
                }
                if (error.response?.status === 403) {
                    navigate("/forbidden");
                }
                return Promise.reject(error);
            },
        );
        return () => http.interceptors.response.eject(interceptorId);
    }, []);

    return (
        <RequireAuth>
            <AppLayout>
                <Suspense fallback={<Spinner size="large" mask />}>
                    <Outlet />
                </Suspense>
            </AppLayout>
        </RequireAuth>
    );
}

export default ProtectedLayout;
