import { createEnum } from "../utils/enum-util";

export const CannedReportFiltersTemplate = createEnum({
    ACCOUNTID: {
        name: "Account",
        key: "#accountid#",
    },
    USERADS: {
        name: "Account",
        key: "#userads#",
    },
    ACCOUNTPUBLISHERS: {
        name: "Account",
        key: "#accountpublishers#",
    },
    DAILYPERIOD: {
        name: "Period",
        key: "#dailyperiod#",
    },
});
