import { isUndefined, isNull, isArray } from "./type-util";

/**
 * Resolves an object property from a string representing the path in dot notation.
 *
 * @param   {Object}          source
 * @param   {String || Array} path
 * @param   {*}               [defaultValue]
 * @returns {*}
 */
export function resolveProp(source, path, defaultValue) {
    let parts = isArray(path) ? path.slice() : String(path).split(".");
    let prop = parts.shift();
    let result = source;

    while (prop) {
        if (isUndefined(result[prop]) || isNull(result[prop])) {
            return defaultValue;
        } else {
            result = result[prop];
        }

        prop = parts.shift();
    }

    return result;
}
