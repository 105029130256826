import { createEnum } from "../utils/enum-util";

export const PricingType = createEnum({
    REV_SHARE: {
        name: "Revenue Share",
    },
    FLAT_CPM: {
        name: "Flat CPM",
    },
});
