import { createEnum } from "../utils/enum-util";

export const MarketplaceStatus = createEnum({
    ACTIVE: {
        name: "Active",
        requestLabel: "Approved",
    },
    PENDING: {
        name: "Pending",
        requestLabel: "Access Requested",
    },
    REJECTED: {
        name: "Rejected",
        requestLabel: "Declined",
    },
    PAUSE: {
        name: "Paused",
        requestLabel: "Access Stopped",
    },
});
