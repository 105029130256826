import PropTypes from "prop-types";
import { Backdrop, BrandCard, Box } from "@beachfront/ui";

import logo from "../../assets/img/bf-logo-horizontal-white.png";

function PublicLayout({ title, children }) {
    return (
        <Backdrop preset="prism">
            <Box width="100%" maxWidth={450}>
                <BrandCard mode="dark" logo={logo} title={title}>
                    {children}
                </BrandCard>
            </Box>
            <Box pt={3} color="white">
                &copy; {new Date().getFullYear()} Beachfront Media LLC
            </Box>
        </Backdrop>
    );
}

PublicLayout.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
};

export default PublicLayout;
