import { useContext } from "react";
import { Layout, Flex, Box } from "@beachfront/ui";

import logoSquare from "../../assets/img/bf-logo-square.png";
import logoHorizontal from "../../assets/img/bf-logo-horizontal-white.png";

function AppBrand() {
    const { siderCollapsed } = useContext(Layout.SiderContext);

    return (
        <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            px={2}
            height={64}
            minHeight={64}
        >
            <Box as="img" alt="Beachfront" width={50} src={logoSquare} hidden={!siderCollapsed} />
            <Box
                as="img"
                alt="Beachfront"
                width={170}
                src={logoHorizontal}
                hidden={siderCollapsed}
            />
        </Flex>
    );
}

export default AppBrand;
