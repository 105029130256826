import { createEnum } from "../utils/enum-util";

export const CustomKey = createEnum({
    ADVERTISER: {
        name: "Advertiser",
    },
    TRAFFICKER: {
        name: "Trafficker",
    },
    SALESPERSON: {
        name: "Salesperson",
    },
    SECONDARY_SALESPERSON: {
        name: "Secondary Salesperson",
    },
    ORDER: {
        name: "Order",
    },
});
