import { createEnum } from "../utils/enum-util";

export const AccountSubType = createEnum({
    ENTERPRISE_PUB_DIRECT: {
        name: "Publisher Direct",
    },
    ENTERPRISE_SSP: {
        name: "SSP",
    },
    ENTERPRISE_AGGREGATOR: {
        name: "Aggregator",
    },
    ENTERPRISE_BUY: {
        name: "Buy",
    },
    ENTERPRISE_MVPD: {
        name: "MVPD",
    },
    UNKNOWN: {
        name: "Unknown",
    },
});
