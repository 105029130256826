import { createEnum } from "../utils/enum-util";

export const AudienceType = createEnum({
    COOKIE: {
        name: "Cookie",
        providerId: null,
    },
    IDFA: {
        name: "IDFA",
        providerId: null,
    },
    GAID: {
        name: "GAID",
        providerId: null,
    },
    IP: {
        name: "IP",
        providerId: null,
    },
    MIX: {
        name: "Mix",
        providerId: null,
    },
    BID_REQUEST: {
        name: "BID_REQUEST",
        providerId: null,
    },
    IDL: {
        name: "RampID",
        providerId: "LIVERAMP",
    },
    RIDA: {
        name: "RIDA",
        providerId: "COMSCORE",
    },
    AAID: {
        name: "AAID",
        providerId: null,
    },
    AFAI: {
        name: "AFAI",
        providerId: "COMSCORE",
    },
    MSAI: {
        name: "MSAI",
        providerId: "COMSCORE",
    },
    DPID: {
        name: "DPID",
        providerId: null,
    },
    ADTV: {
        name: "ADTV",
        providerId: "COMSCORE",
    },
    APTV: {
        name: "APTV",
        providerId: "COMSCORE",
    },
    IRIS_ID: {
        name: "IRIS ID",
        providerId: null,
    },
    GENRE: {
        name: "GENRE",
        providerId: null,
    },
    HOUSEHOLD: {
        name: "HOUSEHOLD",
        providerId: null,
    },
});
