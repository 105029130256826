import { createEnum } from "../utils/enum-util";

import { MetricType } from "./metric-type";

export const Metric = createEnum({
    CUSTOM: {
        name: "Custom Metrics",
    },
    REVENUE: {
        key: "revenue",
        name: "Revenue",
        type: MetricType.CURRENCY,
    },
    REVENUE_TAC: {
        key: "revenueTac",
        name: "Revenue TAC",
        type: MetricType.CURRENCY,
    },
    REVENUE_NET: {
        key: "revenueNet",
        name: "Revenue NET",
        type: MetricType.CURRENCY,
    },
    REVENUE_GROSS: {
        key: "revenueGross",
        name: "Revenue GROSS",
        type: MetricType.CURRENCY,
    },
    REVENUE_MARGIN: {
        key: "revenueMargin",
        name: "Margin",
        type: MetricType.PERCENT,
    },
    IMPRESSIONS: {
        key: "impressions",
        name: "Impressions",
        type: MetricType.COUNT,
    },
    CPM: {
        key: "cpm",
        name: "CPM",
        type: MetricType.CURRENCY,
    },
    CPM_TAC: {
        key: "cpmTac",
        name: "CPM TAC",
        type: MetricType.CURRENCY,
    },
    CPM_NET: {
        key: "cpmNet",
        name: "CPM NET",
        type: MetricType.CURRENCY,
    },
    CPM_GROSS: {
        key: "cpmGross",
        name: "CPM GROSS",
        type: MetricType.CURRENCY,
    },
    FILL_RATE: {
        key: "fillrate",
        name: "Fill Rate",
        type: MetricType.PERCENT,
    },
    VALID_FILL_RATE: {
        key: "invFillrate",
        name: "Valid Fill Rate",
        type: MetricType.PERCENT,
    },
    REQUESTS: {
        key: "requests",
        name: "Requests",
        type: MetricType.COUNT,
    },
    REQUESTS_AD_ATTEMPTS: {
        key: "requestsAdAttempts",
        name: "Ad Attempts",
        type: MetricType.COUNT,
    },
    BLOCKED_IVT_REQUESTS: {
        key: "wopsBot",
        name: "Blocked IVT Requests",
        type: MetricType.COUNT,
    },
    AD_ATTEMPTS: {
        key: "adRequests",
        name: "Ad Attempts",
        type: MetricType.COUNT,
    },
    AD_COST: {
        key: "adAttemptCost",
        name: "Ad Cost",
        type: MetricType.CURRENCY,
    },
    VALID_REQUESTS: {
        key: "validRequests",
        name: "Valid Requests",
        type: MetricType.COUNT,
    },
    AUCTIONS: {
        key: "inventoryRequests",
        name: "Auctions",
        type: MetricType.COUNT,
    },
    USE_RATE: {
        key: "conversionRate",
        name: "Use Rate",
        type: MetricType.PERCENT,
    },
    RESPONSES: {
        key: "impOpp",
        name: "Responses",
        type: MetricType.COUNT,
    },
    BIDS: {
        key: "totalBidPlaced",
        name: "Bids",
        type: MetricType.COUNT,
    },
    BID_RATE: {
        key: "adResponseRate",
        name: "Bid Rate",
        type: MetricType.PERCENT,
    },
    WINS: {
        key: "wins",
        name: "Wins",
        type: MetricType.COUNT,
    },
    WIN_RATE: {
        key: "adWinRate",
        name: "Win Rate",
        type: MetricType.PERCENT,
    },
    AD_USE_RATE: {
        key: "adUseRate",
        name: "Use Rate",
        type: MetricType.PERCENT,
    },
    POD_REQUESTS: {
        key: "podRequests",
        name: "Pod Requests",
        type: MetricType.COUNT,
    },
    SLOTS_REQUESTS: {
        key: "slotCount",
        name: "Slots Requests",
        type: MetricType.COUNT,
    },
    SLOTS_RESPONSES: {
        key: "slotsResponses",
        name: "Slots Responses",
        type: MetricType.COUNT,
    },
    SLOTS_IMPRESSIONS: {
        key: "slotsImpressions",
        name: "Slots Impressions",
        type: MetricType.COUNT,
    },
    DURATION_REQUESTS: {
        key: "slotMaxDuration",
        name: "Duration Requests",
        type: MetricType.SECONDS,
    },
    DURATION_RESPONSES: {
        key: "slotFillDuration",
        name: "Duration Responses",
        type: MetricType.SECONDS,
    },
    DURATION_IMPRESSIONS: {
        key: "impSlotFillDuration",
        name: "Duration Impresssions",
        type: MetricType.SECONDS,
    },
    VCR: {
        key: "vcr",
        name: "VCR",
        type: MetricType.PERCENT,
    },
    CTR: {
        key: "ctr",
        name: "CTR",
        type: MetricType.PERCENT,
    },
});
