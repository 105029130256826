import { createEnum } from "../utils/enum-util";

export const WhiteopsMode = createEnum({
    DISABLED: {
        name: "Disabled",
    },
    PREVENTION_AND_DETECTION: {
        name: "Prevention + Detection",
    },
    DETECTION: {
        name: "Detection",
    },
});
