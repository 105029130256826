import { Button, Heading, Text, Flex } from "@beachfront/ui";
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";

import logoBlack from "../../assets/img/bf-logo-horizontal-black.png";
import logoWhite from "../../assets/img/bf-logo-horizontal-white.png";

function PageForbidden() {
    const navigate = useNavigate();
    const theme = useTheme();
    return (
        <Flex
            gap={3}
            minHeight={300}
            height="calc(100vh - 60px)"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
        >
            <Link to="/">
                <img
                    alt="Beachfront Media"
                    src={theme.name === "light" ? logoBlack : logoWhite}
                    width={320}
                />
            </Link>
            <Heading level={1}>403</Heading>
            <Text>
                You don&apos;t have permission to access this page. Use the button below to get back
                on track.
            </Text>
            <Button type="primary" onClick={() => navigate("/", { replace: true })}>
                Return to Home
            </Button>
        </Flex>
    );
}

export default PageForbidden;
