import { createEnum } from "../utils/enum-util";

export const MarketplaceType = createEnum({
    PUBLIC: {
        name: "Public",
    },
    PRIVATE: {
        name: "Private",
    },
    PENDING: {
        name: "Pending",
    },
    CURATED: {
        name: "Curated",
    },
});
