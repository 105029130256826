import { createEnum } from "../utils/enum-util";

export const MonitorRtlogRequestsFilter = createEnum({
    ALL: {
        name: "All",
        key: "all",
    },
    VALID: {
        name: "Valid",
        key: "valid",
    },
    INVALID: {
        name: "Invalid",
        key: "invalid",
    },
});
