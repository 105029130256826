import { createEnum } from "../utils/enum-util";

export const FloorType = createEnum({
    FLAT_RATE: {
        name: "Flat Rate",
    },
    DYNAMIC_RATE: {
        name: "Dynamic Rate",
    },
    ZERO_RATE: {
        name: "Zero Rate",
    },
    DYNAMIC_PLUS_MARGIN_RATE: {
        name: "Dynamic Plus Margin Rate",
    },
});
