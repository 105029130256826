import { createEnum } from "../utils/enum-util";

export const MediaStatus = createEnum({
    NO_MEDIA: {
        key: "0",
        name: "No Media",
    },
    PROCESSING: {
        key: "1",
        name: "Media Processing",
    },
    COMPLETED: {
        key: "2",
        name: "Media Completed",
    },
    FAILED: {
        key: "3",
        name: "Media Failed",
    },
});
