import { createEnum } from "../utils/enum-util";

export const AccountType = createEnum({
    SUPER: {
        name: "Super",
    },
    ENTERPRISE: {
        name: "Enterprise",
    },
    ADVERTISER: {
        name: "Advertiser",
    },
    PUBLISHER: {
        name: "Publisher",
    },
});
