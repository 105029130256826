import { QueryClientProvider } from "react-query";
import { ToastProvider } from "@beachfront/ui";

import { queryClient } from "../../query-client";
import { ThemeProvider } from "../../context";

import GlobalStyle from "./global-style";
import AppRouter from "./app-router";

function App() {
    return (
        <ThemeProvider>
            <GlobalStyle />
            <ToastProvider>
                <QueryClientProvider client={queryClient}>
                    <AppRouter />
                </QueryClientProvider>
            </ToastProvider>
        </ThemeProvider>
    );
}

export default App;
