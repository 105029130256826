import { createEnum } from "../utils/enum-util";

export const UserSyncType = createEnum({
    DEMAND: {
        name: "Demand",
    },
    SUPPLY: {
        name: "Supply",
    },
});
