import { createEnum } from "../utils/enum-util";

export const MediaType = createEnum({
    ALL: {
        name: "All",
        key: "",
    },
    VIDEO: {
        name: "Video",
        key: "0",
    },
    DISPLAY: {
        name: "Display",
        key: "1",
    },
});
