import { Spinner } from "@beachfront/ui";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { useCurrentUser } from "../../hooks";
import { authService } from "../../auth/auth-service";
import Permission from "../permission";

function RequireAuth({ require, children }) {
    const location = useLocation();
    const [user] = useCurrentUser();

    const authResult = authService.isAuthenticated();

    if (authResult instanceof Promise) {
        return <Spinner />;
    }

    if (!authResult) {
        return <Navigate to="/login" state={{ referrer: location }} />;
    }

    if (!Permission.hasAccess(user, require)) {
        return <Navigate to="/forbidden" state={{ referrer: location }} />;
    }

    return children;
}

RequireAuth.propTypes = {
    require: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.func,
    ]),
    children: PropTypes.node,
};

export default RequireAuth;
