import { createEnum } from "../utils/enum-util";

export const MediaListType = createEnum({
    DOMAIN: {
        name: "Sites",
    },
    APP: {
        name: "Apps",
    },
});
