import { createEnum } from "../utils/enum-util";

export const AccountStatus = createEnum({
    ACTIVE: {
        name: "Active",
    },
    APPROVED: {
        name: "Approved",
    },
    REVIEW: {
        name: "Review",
    },
    PENDING: {
        name: "Pending",
    },
    DECLINED: {
        name: "Declined",
    },
    SUSPENDED: {
        name: "Suspended",
    },
    REJECTED: {
        name: "Rejected",
    },
});
