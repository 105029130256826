import { useQuery, useQueryClient } from "react-query";

import { api } from "../api";

export function useQuickFilters(params) {
    const queryClient = useQueryClient();
    const query = useQuery({
        queryKey: ["dashboard-quick-filters", params],
        queryFn: () => {
            return api.dashboard.getQuickFilters(params).then((res) => res.data.quickfilters);
        },
    });
    const setQueryData = (val) => {
        queryClient.setQueryData(["dashboard-quick-filters", params], val);
    };
    return { ...query, setQueryData };
}
