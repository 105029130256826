import { createEnum } from "../utils/enum-util";

export const CreativeReviewStatus = createEnum({
    APPROVED: {
        name: "Approved Creatives",
    },
    BLOCKED: {
        name: "Blocked Creatives",
    },
    PENDING: {
        name: "Review Pending Creatives",
    },
});
