import { useSessionStorage } from "@beachfront/ui/hooks";
import dayjs from "dayjs";

import { DateRange } from "../enums";

export function useDateRange(storageKey) {
    return useSessionStorage(storageKey, (value) => {
        if (Array.isArray(value)) {
            const [start, end] = value;
            return [dayjs(start), dayjs(end)];
        }
        return DateRange.TODAY.dates;
    });
}
