import { createEnum } from "../utils/enum-util";

export const MonitorRtlogType = createEnum({
    AD: {
        name: "Ad",
        key: "ad",
    },
    APP: {
        name: "App",
        key: "app",
    },
    ADOUT: {
        name: "Ad out",
        key: "adout",
    },
});
