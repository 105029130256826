import { createEnum } from "../utils/enum-util";

export const PlayerSize = createEnum({
    ANY: {
        name: "Any Player Size",
    },
    SMALL: {
        name: "Small",
    },
    MEDIUM: {
        name: "Medium",
    },
    LARGE: {
        name: "Large",
    },
});
