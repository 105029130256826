import { createEnum } from "../utils/enum-util";

export const AdSourceType = createEnum({
    VPAID: {
        key: 8,
        name: "VPAID",
        isRtb: false,
    },
    VAST: {
        key: 2,
        name: "VAST",
        isRtb: false,
    },
    VPAID_RTB: {
        key: 9,
        name: "VPAID RTB",
        isRtb: true,
    },
    VAST_RTB: {
        key: 4,
        name: "VAST RTB",
        isRtb: true,
    },
    DISPLAY_RTB: {
        key: 13,
        name: "Display RTB",
        isRtb: true,
    },
    DIRECT: {
        key: 3,
        name: "Direct",
        isRtb: false,
    },
    DEAL: {
        key: 10,
        name: "Deal",
        isRtb: true,
    },
    BUYER: {
        key: 0,
        name: "Buyer",
        isRtb: false,
    },
});
