import React, { useMemo } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { isFunction } from "../../utils/type-util";
import { useCurrentUser } from "../../hooks";

function Permission({ component: Component, require, className, children, ...rest }) {
    const [user] = useCurrentUser();
    const access = useMemo(() => hasAccess(user, require), [user, require]);

    if (!access) {
        return null;
    }

    if (Component) {
        const allowedChildren = React.Children.toArray(children).filter((child) => {
            if (
                !child.props ||
                !child.props.access ||
                Permission.hasAccess(user, child.props.access)
            ) {
                return true;
            }

            return false;
        });

        return allowedChildren.length === 0 ? null : (
            <Component {...rest}>{allowedChildren}</Component>
        );
    }

    if (className) {
        return React.Children.toArray(children).map((child) =>
            React.cloneElement(child, {
                className: cx(child?.props?.className, className),
            }),
        );
    }

    return children;
}

function hasAccess(user, require) {
    if (!require) {
        return true;
    }

    if (isFunction(require)) {
        return Boolean(require(user));
    }

    if (Array.isArray(require)) {
        return require.some((req) => {
            return user?.permissions.includes(req) || user?.account_type.includes(req);
        });
    }

    return user?.permissions.includes(require) || user?.account_type.includes(require);
}

Permission.hasAccess = hasAccess;
Permission.propTypes = {
    component: PropTypes.elementType,
    require: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.func,
    ]),
    className: PropTypes.string,
    children: PropTypes.node,
};

export default Permission;
