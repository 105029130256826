import { createEnum } from "../utils/enum-util";

export const InventoryListRule = createEnum({
    ALL: {
        key: 2,
        name: "Use All Inventory",
    },
    INCLUDE: {
        key: 0,
        name: "Include Selected Inventory",
    },
    EXCLUDE: {
        key: 1,
        name: "Exclude Selected Inventory",
    },
});
