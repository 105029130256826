import { createEnum } from "../utils/enum-util";

export const QualityRuleMode = createEnum({
    AUTO_APPROVE: {
        name: "Auto Approve",
    },
    REVIEW_REQUIRED: {
        name: "Review Required",
    },
    DISABLED: {
        name: "Disabled",
    },
});
