import { createEnum } from "../utils/enum-util";

export const RequestCapType = createEnum({
    PER_DAY: {
        key: 1,
        name: "Per Day",
    },
    PER_WEEK: {
        key: 2,
        name: "Per Week",
    },
    PER_MONTH: {
        key: 3,
        name: "Per Month",
    },
    UNLIMITED: {
        key: 4,
        name: "Unlimited",
    },
});
