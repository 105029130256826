import { createEnum } from "../utils/enum-util";

export const KeyType = createEnum({
    ADVERTISER: {
        name: "Advertiser",
    },
    TRAFFICKER: {
        name: "Trafficker",
    },
    SALESPERSON: {
        name: "Salespeople",
    },
    SECONDARY_SALESPERSON: {
        name: "Secondary Salespeople",
    },
    ORDER: {
        name: "Order",
    },
});
