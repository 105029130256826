import { createEnum } from "../utils/enum-util";

export const IabCategory = createEnum({
    IAB1: { name: "Arts & Entertainment" },
    IAB2: { name: "Automotive" },
    IAB3: { name: "Business" },
    IAB4: { name: "Careers" },
    IAB5: { name: "Education" },
    IAB6: { name: "Family & Parenting" },
    IAB7: { name: "Health & Fitness" },
    IAB8: { name: "Food & Drink" },
    IAB9: { name: "Hobbies & Interests" },
    IAB10: { name: "Home & Garden" },
    IAB11: { name: "Law, Gov't & Politics" },
    IAB12: { name: "News" },
    IAB13: { name: "Personal Finance" },
    IAB14: { name: "Society" },
    IAB15: { name: "Science" },
    IAB16: { name: "Pets" },
    IAB17: { name: "Sports" },
    IAB18: { name: "Style & Fashion" },
    IAB19: { name: "Technology & Computing" },
    IAB20: { name: "Travel" },
    IAB21: { name: "Real Estate" },
    IAB22: { name: "Shopping" },
    IAB23: { name: "Religion & Spirituality" },
    IAB24: { name: "Uncategorized" },
    IAB25: { name: "Non-Standard Content" },
    IAB26: { name: "Illegal Content" },
    "IAB1-1": { name: "Books & Literature" },
    "IAB1-2": { name: "Celebrity Fan/Gossip" },
    "IAB1-3": { name: "Fine Art" },
    "IAB1-4": { name: "Humor" },
    "IAB1-5": { name: "Movies" },
    "IAB1-6": { name: "Music" },
    "IAB1-7": { name: "Television" },
    "IAB2-1": { name: "Auto Parts" },
    "IAB2-2": { name: "Auto Repair" },
    "IAB2-3": { name: "Buying/Selling Cars" },
    "IAB2-4": { name: "Car Culture" },
    "IAB2-5": { name: "Certified Pre-Owned" },
    "IAB2-6": { name: "Convertible" },
    "IAB2-7": { name: "Coupe" },
    "IAB2-8": { name: "Crossover" },
    "IAB2-9": { name: "Diesel" },
    "IAB2-10": { name: "Electric Vehicle" },
    "IAB2-11": { name: "Hatchback" },
    "IAB2-12": { name: "Hybrid" },
    "IAB2-13": { name: "Luxury" },
    "IAB2-14": { name: "MiniVan" },
    "IAB2-15": { name: "Mororcycles" },
    "IAB2-16": { name: "Off-Road Vehicles" },
    "IAB2-17": { name: "Performance Vehicles" },
    "IAB2-18": { name: "Pickup" },
    "IAB2-19": { name: "Road-Side Assistance" },
    "IAB2-20": { name: "Sedan" },
    "IAB2-21": { name: "Trucks & Accessories" },
    "IAB2-22": { name: "Vintage Cars" },
    "IAB2-23": { name: "Wagon" },
    "IAB3-1": { name: "Advertising" },
    "IAB3-2": { name: "Agriculture" },
    "IAB3-3": { name: "Biotech/Biomedical" },
    "IAB3-4": { name: "Business Software" },
    "IAB3-5": { name: "Construction" },
    "IAB3-6": { name: "Forestry" },
    "IAB3-7": { name: "Government" },
    "IAB3-8": { name: "Green Solutions" },
    "IAB3-9": { name: "Human Resources" },
    "IAB3-10": { name: "Logistics" },
    "IAB3-11": { name: "Marketing" },
    "IAB3-12": { name: "Metals" },
    "IAB4-1": { name: "Career Planning" },
    "IAB4-2": { name: "College" },
    "IAB4-3": { name: "Financial Aid" },
    "IAB4-4": { name: "Job Fairs" },
    "IAB4-5": { name: "Job Search" },
    "IAB4-6": { name: "Resume Writing/Advice" },
    "IAB4-7": { name: "Nursing" },
    "IAB4-8": { name: "Scholarships" },
    "IAB4-9": { name: "Telecommuting" },
    "IAB4-10": { name: "U.S. Military" },
    "IAB4-11": { name: "Career Advice" },
    "IAB5-1": { name: "7-12 Education" },
    "IAB5-2": { name: "Adult Education" },
    "IAB5-3": { name: "Art History" },
    "IAB5-4": { name: "Colledge Administration" },
    "IAB5-5": { name: "College Life" },
    "IAB5-6": { name: "Distance Learning" },
    "IAB5-7": { name: "English as a 2nd Language" },
    "IAB5-8": { name: "Language Learning" },
    "IAB5-9": { name: "Graduate School" },
    "IAB5-10": { name: "Homeschooling" },
    "IAB5-11": { name: "Homework/Study Tips" },
    "IAB5-12": { name: "K-6 Educators" },
    "IAB5-13": { name: "Private School" },
    "IAB5-14": { name: "Special Education" },
    "IAB5-15": { name: "Studying Business" },
    "IAB6-1": { name: "Adoption" },
    "IAB6-2": { name: "Babies & Toddlers" },
    "IAB6-3": { name: "Daycare/Pre School" },
    "IAB6-4": { name: "Family Internet" },
    "IAB6-5": { name: "Parenting - K-6 Kids" },
    "IAB6-6": { name: "Parenting teens" },
    "IAB6-7": { name: "Pregnancy" },
    "IAB6-8": { name: "Special Needs Kids" },
    "IAB6-9": { name: "Eldercare" },
    "IAB7-1": { name: "Exercise" },
    "IAB7-2": { name: "A.D.D." },
    "IAB7-3": { name: "AIDS/HIV" },
    "IAB7-4": { name: "Allergies" },
    "IAB7-5": { name: "Alternative Medicine" },
    "IAB7-6": { name: "Arthritis" },
    "IAB7-7": { name: "Asthma" },
    "IAB7-8": { name: "Autism/PDD" },
    "IAB7-9": { name: "Bipolar Disorder" },
    "IAB7-10": { name: "Brain Tumor" },
    "IAB7-11": { name: "Cancer" },
    "IAB7-12": { name: "Cholesterol" },
    "IAB7-13": { name: "Chronic Fatigue Syndrome" },
    "IAB7-14": { name: "Chronic Pain" },
    "IAB7-15": { name: "Cold & Flu" },
    "IAB7-16": { name: "Deafness" },
    "IAB7-17": { name: "Dental Care" },
    "IAB7-18": { name: "Depression" },
    "IAB7-19": { name: "Dermatology" },
    "IAB7-20": { name: "Diabetes" },
    "IAB7-21": { name: "Epilepsy" },
    "IAB7-22": { name: "GERD/Acid Reflux" },
    "IAB7-23": { name: "Headaches/Migraines" },
    "IAB7-24": { name: "Heart Disease" },
    "IAB7-25": { name: "Herbs for Health" },
    "IAB7-26": { name: "Holistic Healing" },
    "IAB7-27": { name: "IBS/Crohn's Disease" },
    "IAB7-28": { name: "Incest/Abuse Support" },
    "IAB7-29": { name: "Incontinence" },
    "IAB7-30": { name: "Infertility" },
    "IAB7-31": { name: "Men's Health" },
    "IAB7-32": { name: "Nutrition" },
    "IAB7-33": { name: "Orthopedics" },
    "IAB7-34": { name: "Panic/Anxiety Disorders" },
    "IAB7-35": { name: "Pediatrics" },
    "IAB7-36": { name: "Physical Therapy" },
    "IAB7-37": { name: "Psychology/Psychiatry" },
    "IAB7-38": { name: "Senor Health" },
    "IAB7-39": { name: "Sexuality" },
    "IAB7-40": { name: "Sleep Disorders" },
    "IAB7-41": { name: "Smoking Cessation" },
    "IAB7-42": { name: "Substance Abuse" },
    "IAB7-43": { name: "Thyroid Disease" },
    "IAB7-44": { name: "Weight Loss" },
    "IAB7-45": { name: "Women's Health" },
    "IAB8-1": { name: "American Cuisine" },
    "IAB8-2": { name: "Barbecues & Grilling" },
    "IAB8-3": { name: "Cajun/Creole" },
    "IAB8-4": { name: "Chinese Cuisine" },
    "IAB8-5": { name: "Cocktails/Beer" },
    "IAB8-6": { name: "Coffee/Tea" },
    "IAB8-7": { name: "Cuisine-Specific" },
    "IAB8-8": { name: "Desserts & Baking" },
    "IAB8-9": { name: "Dining Out" },
    "IAB8-10": { name: "Food Allergies" },
    "IAB8-11": { name: "French Cuisine" },
    "IAB8-12": { name: "Health/Lowfat Cooking" },
    "IAB8-13": { name: "Italian Cuisine" },
    "IAB8-14": { name: "Japanese Cuisine" },
    "IAB8-15": { name: "Mexican Cuisine" },
    "IAB8-16": { name: "Vegan" },
    "IAB8-17": { name: "Vegetarian" },
    "IAB8-18": { name: "Wine" },
    "IAB9-1": { name: "Art/Technology" },
    "IAB9-2": { name: "Arts & Crafts" },
    "IAB9-3": { name: "Beadwork" },
    "IAB9-4": { name: "Birdwatching" },
    "IAB9-5": { name: "Board Games/Puzzles" },
    "IAB9-6": { name: "Candle & Soap Making" },
    "IAB9-7": { name: "Card Games" },
    "IAB9-8": { name: "Chess" },
    "IAB9-9": { name: "Cigars" },
    "IAB9-10": { name: "Collecting" },
    "IAB9-11": { name: "Comic Books" },
    "IAB9-12": { name: "Drawing/Sketching" },
    "IAB9-13": { name: "Freelance Writing" },
    "IAB9-14": { name: "Genealogy" },
    "IAB9-15": { name: "Getting Published" },
    "IAB9-16": { name: "Guitar" },
    "IAB9-17": { name: "Home Recording" },
    "IAB9-18": { name: "Investors & Patents" },
    "IAB9-19": { name: "Jewelry Making" },
    "IAB9-20": { name: "Magic & Illusion" },
    "IAB9-21": { name: "Needlework" },
    "IAB9-22": { name: "Painting" },
    "IAB9-23": { name: "Photography" },
    "IAB9-24": { name: "Radio" },
    "IAB9-25": { name: "Roleplaying Games" },
    "IAB9-26": { name: "Sci-Fi & Fantasy" },
    "IAB9-27": { name: "Scrapbooking" },
    "IAB9-28": { name: "Screenwriting" },
    "IAB9-29": { name: "Stamps & Coins" },
    "IAB9-30": { name: "Video & Computer Games" },
    "IAB9-31": { name: "Woodworking" },
    "IAB10-1": { name: "Appliances" },
    "IAB10-2": { name: "Entertaining" },
    "IAB10-3": { name: "Environmental Safety" },
    "IAB10-4": { name: "Gardening" },
    "IAB10-5": { name: "Home Repair" },
    "IAB10-6": { name: "Home Theater" },
    "IAB10-7": { name: "Interior Decorating" },
    "IAB10-8": { name: "Landscaping" },
    "IAB10-9": { name: "Remodeling & Construction" },
    "IAB11-1": { name: "Immigration" },
    "IAB11-2": { name: "Legal Issues" },
    "IAB11-3": { name: "U.S. Government Resources" },
    "IAB11-4": { name: "Politics" },
    "IAB11-5": { name: "Commentary" },
    "IAB12-1": { name: "International News" },
    "IAB12-2": { name: "National News" },
    "IAB12-3": { name: "Local News" },
    "IAB13-1": { name: "Beginning Investing" },
    "IAB13-2": { name: "Credit/Debt & Loans" },
    "IAB13-3": { name: "Financial News" },
    "IAB13-4": { name: "Financial Planning" },
    "IAB13-5": { name: "Hedge Fund" },
    "IAB13-6": { name: "Insurance" },
    "IAB13-7": { name: "Investing" },
    "IAB13-8": { name: "Mutual Funds" },
    "IAB13-9": { name: "Options" },
    "IAB13-10": { name: "Retirement Planning" },
    "IAB13-11": { name: "Stocks" },
    "IAB13-12": { name: "Tax Planning" },
    "IAB14-1": { name: "Dating" },
    "IAB14-2": { name: "Divorce Support" },
    "IAB14-3": { name: "Gay Life" },
    "IAB14-4": { name: "Marriage" },
    "IAB14-5": { name: "Senior Living" },
    "IAB14-6": { name: "Teens" },
    "IAB14-7": { name: "Weddings" },
    "IAB14-8": { name: "Ethnic Specific" },
    "IAB15-1": { name: "Astrology" },
    "IAB15-2": { name: "Biology" },
    "IAB15-3": { name: "Chemistry" },
    "IAB15-4": { name: "Geology" },
    "IAB15-5": { name: "Paranormal Phenomena" },
    "IAB15-6": { name: "Physics" },
    "IAB15-7": { name: "Space/Astronomy" },
    "IAB15-8": { name: "Geography" },
    "IAB15-9": { name: "Botany" },
    "IAB15-10": { name: "Weather" },
    "IAB16-1": { name: "Aquariums" },
    "IAB16-2": { name: "Birds" },
    "IAB16-3": { name: "Cats" },
    "IAB16-4": { name: "Dogs" },
    "IAB16-5": { name: "Large Animals" },
    "IAB16-6": { name: "Reptiles" },
    "IAB16-7": { name: "Veterinary Medicine" },
    "IAB17-1": { name: "Auto Racing" },
    "IAB17-2": { name: "Baseball" },
    "IAB17-3": { name: "Bicycling" },
    "IAB17-4": { name: "Bodybuilding" },
    "IAB17-5": { name: "Boxing" },
    "IAB17-6": { name: "Canoeing/Kayaking" },
    "IAB17-7": { name: "Cheerleading" },
    "IAB17-8": { name: "Climbing" },
    "IAB17-9": { name: "Cricket" },
    "IAB17-10": { name: "Figure Skating" },
    "IAB17-11": { name: "Fly Fishing" },
    "IAB17-12": { name: "Football" },
    "IAB17-13": { name: "Freshwater Fishing" },
    "IAB17-14": { name: "Game & Fish" },
    "IAB17-15": { name: "Golf" },
    "IAB17-16": { name: "Horse Racing" },
    "IAB17-17": { name: "Horses" },
    "IAB17-18": { name: "Hunting/Shooting" },
    "IAB17-19": { name: "Inline Skating" },
    "IAB17-20": { name: "Martial Arts" },
    "IAB17-21": { name: "Mountain Biking" },
    "IAB17-22": { name: "NASCAR Racing" },
    "IAB17-23": { name: "Olympics" },
    "IAB17-24": { name: "Paintball" },
    "IAB17-25": { name: "Power & Motorcycles" },
    "IAB17-26": { name: "Pro Basketball" },
    "IAB17-27": { name: "Pro Ice Hockey" },
    "IAB17-28": { name: "Rodeo" },
    "IAB17-29": { name: "Rugby" },
    "IAB17-30": { name: "Running/Jogging" },
    "IAB17-31": { name: "Sailing" },
    "IAB17-32": { name: "Saltwater Fishing" },
    "IAB17-33": { name: "Scuba Diving" },
    "IAB17-34": { name: "Skateboarding" },
    "IAB17-35": { name: "Skiing" },
    "IAB17-36": { name: "Snowboarding" },
    "IAB17-37": { name: "Surfing/Bodyboarding" },
    "IAB17-38": { name: "Swimming" },
    "IAB17-39": { name: "Table Tennis/Ping-Pong" },
    "IAB17-40": { name: "Tennis" },
    "IAB17-41": { name: "Volleyball" },
    "IAB17-42": { name: "Walking" },
    "IAB17-43": { name: "Waterski/Wakeboard" },
    "IAB17-44": { name: "World Soccer" },
    "IAB18-1": { name: "Beauty" },
    "IAB18-2": { name: "Body Art" },
    "IAB18-3": { name: "Fashion" },
    "IAB18-4": { name: "Jewelry" },
    "IAB18-5": { name: "Clothing" },
    "IAB18-6": { name: "Accessories" },
    "IAB19-1": { name: "3-D Graphics" },
    "IAB19-2": { name: "Animation" },
    "IAB19-3": { name: "Antivirus Software" },
    "IAB19-4": { name: "C/C++" },
    "IAB19-5": { name: "Cameras & Camcorders" },
    "IAB19-6": { name: "Cell Phones" },
    "IAB19-7": { name: "Computer Certification" },
    "IAB19-8": { name: "Computer Networking" },
    "IAB19-9": { name: "Computer Peripherals" },
    "IAB19-10": { name: "Computer Reviews" },
    "IAB19-11": { name: "Data Centers" },
    "IAB19-12": { name: "Databases" },
    "IAB19-13": { name: "Desktop Publishing" },
    "IAB19-14": { name: "Desktop Video" },
    "IAB19-15": { name: "Email" },
    "IAB19-16": { name: "Graphics Software" },
    "IAB19-17": { name: "Home Video/DVD" },
    "IAB19-18": { name: "Internet Technology" },
    "IAB19-19": { name: "Java" },
    "IAB19-20": { name: "JavaScript" },
    "IAB19-21": { name: "Mac Support" },
    "IAB19-22": { name: "MP3/MIDI" },
    "IAB19-23": { name: "Net Conferencing" },
    "IAB19-24": { name: "Net for Beginners" },
    "IAB19-25": { name: "Network Security" },
    "IAB19-26": { name: "Palmtops/PDAs" },
    "IAB19-27": { name: "PC Support" },
    "IAB19-28": { name: "Portable" },
    "IAB19-29": { name: "Entertainment" },
    "IAB19-30": { name: "Shareware/Freeware" },
    "IAB19-31": { name: "Unix" },
    "IAB19-32": { name: "Visual Basic" },
    "IAB19-33": { name: "Web Clip Art" },
    "IAB19-34": { name: "Web Design/HTML" },
    "IAB19-35": { name: "Web Search" },
    "IAB19-36": { name: "Windows" },
    "IAB20-1": { name: "Adventure Travel" },
    "IAB20-2": { name: "Africa" },
    "IAB20-3": { name: "Air Travel" },
    "IAB20-4": { name: "Australia & New Zealand" },
    "IAB20-5": { name: "Bed & Breakfasts" },
    "IAB20-6": { name: "Budget Travel" },
    "IAB20-7": { name: "Business Travel" },
    "IAB20-8": { name: "By US Locale" },
    "IAB20-9": { name: "Camping" },
    "IAB20-10": { name: "Canada" },
    "IAB20-11": { name: "Caribbean" },
    "IAB20-12": { name: "Cruises" },
    "IAB20-13": { name: "Eastern Europe" },
    "IAB20-14": { name: "Europe" },
    "IAB20-15": { name: "France" },
    "IAB20-16": { name: "Greece" },
    "IAB20-17": { name: "Honeymoons/Getaways" },
    "IAB20-18": { name: "Hotels" },
    "IAB20-19": { name: "Italy" },
    "IAB20-20": { name: "Japan" },
    "IAB20-21": { name: "Mexico & Central America" },
    "IAB20-22": { name: "National Parks" },
    "IAB20-23": { name: "South America" },
    "IAB20-24": { name: "Spas" },
    "IAB20-25": { name: "Theme Parks" },
    "IAB20-26": { name: "Traveling with Kids" },
    "IAB20-27": { name: "United Kingdom" },
    "IAB21-1": { name: "Apartments" },
    "IAB21-2": { name: "Architects" },
    "IAB21-3": { name: "Buying/Selling Homes" },
    "IAB22-1": { name: "Contests & Freebies" },
    "IAB22-2": { name: "Couponing" },
    "IAB22-3": { name: "Comparison" },
    "IAB22-4": { name: "Engines" },
    "IAB23-1": { name: "Alternative Religions" },
    "IAB23-2": { name: "Atheism/Agnosticism" },
    "IAB23-3": { name: "Buddhism" },
    "IAB23-4": { name: "Catholicism" },
    "IAB23-5": { name: "Christianity" },
    "IAB23-6": { name: "Hinduism" },
    "IAB23-7": { name: "Islam" },
    "IAB23-8": { name: "Judaism" },
    "IAB23-9": { name: "Latter-Day Saints" },
    "IAB23-10": { name: "Pagan/Wiccan" },
});
