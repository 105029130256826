import axios from "axios";

export const http = axios.create({
    baseURL: "//" + process.env.IO_FE_API_HOST,
    withCredentials: true,
});

http.interceptors.response.use((res) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    res.data = res.data.data;
    return res;
});
