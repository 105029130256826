import { useQuery } from "react-query";

import { api } from "../api";

export function useDashboardConfig(params) {
    return useQuery({
        queryKey: ["dashboard-config", params],
        queryFn: () => {
            return api.dashboard.getConfig(params).then((res) => res.data);
        },
    });
}
