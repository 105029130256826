import { useMemo, useReducer } from "react";
import { useQuery } from "react-query";
import { useUpdateEffect } from "@beachfront/ui/hooks";

import { makeArray, isArray, isObject, isEmptyString } from "../utils/type-util";
import { resolveProp } from "../utils/object-util";
import { parseFields } from "../utils/schema-util";

/**
 * Uses a data source for table components with server-side paging, sorting, and filtering.
 *
 * @param {String || Array} queryKey
 * @param {Function}        queryFn
 * @param {Object}          options
 * @returns {{
 *     data: Array,
 *     total: Number,
 *     isError: Object,
 *     isLoading: Boolean,
 *     query: { pagination: Object, sorter: Object },
 *     setPagination: function(*),
 *     setSorter: function(*),
 *     selectedRowKeys: Array,
 *     setSelectedRowKeys: function(*),
 *     clearSelectedRowKeys: function(),
 *     refetch: function()
 * }}
 */
export function useTableSource(queryKey, queryFn, options) {
    const { payload, filters = [], schema = {}, defaultPageSize, defaultSorter, ...opt } = options;

    const [state, dispatch] = useReducer(
        reduceState,
        { defaultPageSize, defaultSorter },
        initState
    );

    const serverFilters = useMemo(() => formatFilter(filters), [JSON.stringify(filters)]);
    const { query, selectedRowKeys } = state;

    const { data, refetch, ...rest } = useQuery({
        queryKey: [...makeArray(queryKey), payload, query],
        queryFn: async () => {
            const res = await queryFn({
                skip: query.pagination.pageSize * (query.pagination.current - 1),
                pageSize: query.pagination.pageSize,
                sort: formatSort(query.sorter),
                filter: serverFilters,
                ...payload,
            });
            const data = resolveProp(res.data, schema.data, []);
            const total = resolveProp(res.data, schema.total, 0);
            parseFields(data, schema.fields);
            return { data, total };
        },
        ...opt,
    });

    useUpdateEffect(() => {
        if (query.pagination.current === 1) {
            refetch();
        } else {
            dispatch({ query: { pagination: { current: 1 } } });
        }
    }, [serverFilters]);

    const setPagination = (pagination) => {
        dispatch({ query: { pagination } });
    };

    const setSorter = (sorter) => {
        dispatch({ query: { sorter } });
    };

    const setSelectedRowKeys = (keys) => {
        dispatch({ selectedRowKeys: keys });
    };

    const clearSelectedRowKeys = () => {
        dispatch({ selectedRowKeys: [] });
    };

    return {
        ...data,
        ...rest,
        refetch,
        query,
        serverFilters,
        setPagination,
        setSorter,
        selectedRowKeys,
        setSelectedRowKeys,
        clearSelectedRowKeys,
    };
}

/**
 * Initializer the table source state.
 *
 * @param   {Number} props.defaultPageSize
 * @param   {Object} props.defaultSorter
 * @returns {Object}
 */
function initState({ defaultPageSize, defaultSorter }) {
    return {
        selectedRowKeys: [],
        query: {
            pagination: {
                current: 1,
                pageSize: Number(defaultPageSize || 25),
                showSizeChanger: true,
                pageSizeOptions: ["25", "50", "100", "200"],
                showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total} items`,
            },
            sorter: defaultSorter ?? {},
        },
    };
}

/**
 * Reducer for the table source state.
 *
 * @param   {Object} state
 * @param   {Object} action
 * @returns {Object}
 */
function reduceState(state, action) {
    if (action.query) {
        return {
            ...state,
            ...action,
            query: {
                ...state.query,
                ...action.query,
                pagination: {
                    ...state.query?.pagination,
                    ...action.query?.pagination,
                },
            },
        };
    }

    return {
        ...state,
        ...action,
    };
}

/**
 * Formats a sort query to a string.
 *
 * @param   {Object} sorter
 * @returns {String || null}
 */
function formatSort(sorter) {
    const { field, order } = sorter;

    if (order && field && field.length > 0) {
        const path = isArray(field) ? field.join(".") : field;
        return path + "," + order;
    }

    return null;
}

/**
 * Formats a filter query to a string.
 *
 * @param   {Array} filters
 * @returns {String || null}
 */
function formatFilter(filters) {
    const result = filters
        .filter((desc) => !isEmptyString(desc.value))
        .map((desc) => {
            return {
                field: desc.dataIndex,
                value: isObject(desc) ? desc.value : desc,
                operator: isObject(desc) ? desc.operator : "eq",
            };
        });

    if (result.length > 0) {
        return JSON.stringify(result);
    }

    return null;
}
