import { createEnum } from "../utils/enum-util";

export const DashboardType = createEnum({
    INVENTORY: {
        name: "Inventory",
    },
    PODDING: {
        name: "Podding",
    },
    ADS: {
        name: "Ads",
    },
    ADS_ADVERTISERS: {
        name: "Advertisers",
    },
    CAMPAIGNS: {
        name: "Campaigns",
    },
    CREATIVES: {
        name: "Creatives",
    },
    ACCOUNTS: {
        name: "Accounts",
    },
    PUBLISHERS: {
        name: "Publishers",
    },
});
