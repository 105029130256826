import { useMemo } from "react";
import { Link, useLocation, matchPath } from "react-router-dom";
import { Layout, NavMenu, Flex, Box } from "@beachfront/ui";
import { useSessionStorage, useDocumentTitle } from "@beachfront/ui/hooks";

import { useCurrentUser, useOfflineNotification } from "../../hooks";
import Permission from "../../components/permission";

import appMenus from "./app-menus";
import AppAccount from "./app-account";
import AppSider from "./app-sider";

function AppLayout({ children }) {
    const [collapsed, setCollapsed] = useSessionStorage("sider-collapsed", false);
    const [user] = useCurrentUser();
    const location = useLocation();
    const title = generateTitle(appMenus, location.pathname);

    useDocumentTitle(title);
    useOfflineNotification();

    const selectedKeys = useMemo(() => {
        const match = matchPath("/billing/*", location.pathname);
        return match ? ["2"] : ["0"];
    }, [location.pathname]);

    const renderLink = ({ href, label }) => {
        return href?.startsWith("/") ? <Link to={href}>{label}</Link> : <a href={href}>{label}</a>;
    };

    const menuItems = [
        {
            key: "0",
            title: "Marketplace (SSP)",
            href: "/overview",
        },
        {
            key: "1",
            title: "Deals",
            href: "https://deals.beachfront.io",
        },
    ];

    if (
        Permission.hasAccess(user, "users.settings_billing.read") &&
        (!user.isConsolidatedBilling || user.isOrganizationAdmin)
    ) {
        menuItems.push({
            key: "2",
            title: "Billing",
            href: "/billing",
        });
    }

    return (
        <Layout>
            <Layout.Sider
                width={230}
                bg="dark.bg"
                collapsed={collapsed}
                onCollapseToggle={setCollapsed}
                collapsible
            >
                <AppSider />
            </Layout.Sider>
            <Layout.Header height="40px" fixed>
                <Flex px={3} justifyContent="space-between" bg="dark.bg">
                    <Box>
                        <NavMenu
                            primary
                            orientation="horizontal"
                            bg="transparent"
                            variant="dark"
                            selectedKeys={selectedKeys}
                            renderLink={renderLink}
                            items={menuItems}
                            itemHeight={32}
                        />
                    </Box>
                    <AppAccount />
                </Flex>
            </Layout.Header>
            <Layout.Content px={4} pb={3} mt="40px">
                {children}
            </Layout.Content>
        </Layout>
    );
}

function generateTitle(menus, pathname) {
    for (let item of menus) {
        if (item.href) {
            const match = matchPath(`${item.href}/*`, pathname);
            if (match) {
                return item.title;
            }
        } else if (item.children) {
            const child = item.children.find((c) => matchPath(`${c.href}/*`, pathname));
            if (child) {
                return `${item.title} / ${child.title}`;
            }
        }
    }
    return "Beachfront";
}

export default AppLayout;
