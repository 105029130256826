import { Input, Button, Paragraph, useToast } from "@beachfront/ui";
import { LockOutlined } from "@beachfront/ui/icons";
import { Form, Field, SubmitError, FORM_ERROR } from "@beachfront/ui/forms";
import { useDocumentTitle } from "@beachfront/ui/hooks";
import { Navigate, useParams, useNavigate, useLocation } from "react-router-dom";

import { api } from "../../api";
import { authService } from "../../auth";
import PublicLayout from "../../components/public-layout";

function RegisterPage() {
    const { companyId, usersId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useToast();

    useDocumentTitle("Register");

    const validate = (values) => {
        const errors = {};

        if (!values.password) {
            errors.password = "Please enter a password.";
        }

        if (values.password !== values.confirm_password) {
            errors.confirm_password = "Passwords do not match.";
        }

        if (!values.confirm_password) {
            errors.confirm_password = "Please confirm your password.";
        }

        return errors;
    };

    const onSubmit = ({ password }) => {
        return api.register({ companyId, usersId, password }).then(
            (response) => {
                const user = { password, username: response.data.username };
                toast.success({ title: "You are registered. Welcome!" });
                navigate("/login", { state: { user } });
            },
            () => {
                return { [FORM_ERROR]: "Unable to register." };
            }
        );
    };

    if (authService.isAuthenticated()) {
        const referrer = location.state?.referrer?.pathname ?? "/overview";
        return <Navigate to={referrer} replace />;
    }

    return (
        <PublicLayout title="Register">
            <Form validate={validate} onSubmit={onSubmit}>
                {({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <SubmitError mb={3} />
                        <Paragraph>Register by creating a password below.</Paragraph>
                        <Field name="password">
                            <Input.Password
                                size="large"
                                placeholder="Password"
                                autoComplete="new-password"
                                prefix={<LockOutlined />}
                                maxLength={255}
                            />
                        </Field>
                        <Field name="confirm_password">
                            <Input.Password
                                size="large"
                                placeholder="Confirm Password"
                                autoComplete="new-password"
                                prefix={<LockOutlined />}
                                maxLength={255}
                            />
                        </Field>
                        <Button
                            block
                            type="primary"
                            htmlType="submit"
                            size="large"
                            loading={submitting}
                        >
                            Register
                        </Button>
                    </form>
                )}
            </Form>
        </PublicLayout>
    );
}

export default RegisterPage;
