import { createEnum } from "../utils/enum-util";

export const AdPacingStatus = createEnum({
    DISABLED: {
        name: "Disabled",
    },
    ON_SCHEDULE: {
        name: "On Pace",
    },
    UNDER_DELIVERING: {
        name: "Under Pacing",
    },
    OVER_DELIVERING: {
        name: "Over Pacing",
    },
});
