import { useMemo } from "react";
import { ThemeProvider as BaseThemeProvider } from "styled-components";
import { createTheme } from "@beachfront/ui";
import { setTwoToneColor } from "@beachfront/ui/icons";
import { useLocalStorage } from "@beachfront/ui/hooks";

function ThemeProvider({ children }) {
    const [baseTheme] = useLocalStorage("base-theme", "light");
    const theme = useMemo(() => createTheme({ base: baseTheme }), [baseTheme]);

    setTwoToneColor([theme.colors.primary.base, theme.colors.primary.light]);

    return <BaseThemeProvider theme={theme}>{children}</BaseThemeProvider>;
}

export default ThemeProvider;
