import { createEnum } from "../utils/enum-util";

export const MonitorRtlogItemType = createEnum({
    SYSTEM: {
        name: "System",
    },
    REQUEST: {
        name: "Request",
    },
    RESPONSE: {
        name: "Response",
    },
});
