import { Navigate, useLocation } from "react-router-dom";
import { Spinner } from "@beachfront/ui";

import { authService } from "../../auth";

function LogoutPage() {
    const location = useLocation();
    const authResult = authService.logout();

    if (authResult instanceof Promise) {
        return <Spinner />;
    }

    return <Navigate to="/login" state={location.state} replace />;
}

export default LogoutPage;
