import { createEnum } from "../utils/enum-util";

export const UserSyncPlatform = createEnum({
    ALL: {
        name: "All",
    },
    DESKTOP: {
        name: "Desktop",
    },
    MOBILE: {
        name: "Mobile",
    },
});
