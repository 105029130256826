import { http } from "./http";

export const api = {
    getBaseUrl() {
        return http.defaults.baseURL;
    },

    getAdminUrl() {
        return "//" + process.env.IO_FE_ADMIN_HOST;
    },

    getSSORedirectUrl() {
        return http.defaults.baseURL + "/restful/auth/opauth/keycloak";
    },

    getLoginAsRedirectUrl() {
        return http.defaults.baseURL + "/restful/auth/loginas";
    },

    getLoginAsPublisherRedirectUrl() {
        return http.defaults.baseURL + "/restful/auth/loginaspub";
    },

    check() {
        return http.get("/restful/auth");
    },

    login({ username, password, remember }) {
        return http.post(
            "/restful/auth",
            { username, password, remember },
            { auth: { username, password } },
        );
    },

    logout() {
        return http.get("restful/auth/logout");
    },

    forgotPassword({ email }) {
        return http.post("/restful/forgot", { email });
    },

    register({ companyId, usersId, password }) {
        // @TODO: implement?
        return http.post("/io/api/public/register", { companyId, usersId, password });
    },

    resetPassword({ token, password }) {
        return http.post("/restful/reset", { token, password });
    },

    dashboard: {
        getDownloadExcelUrl() {
            return http.defaults.baseURL + "/restful/dashboard/excel";
        },

        getDownloadCSVUrl() {
            return http.defaults.baseURL + "/restful/dashboard/csv";
        },

        getConfig(params) {
            return http.get("/restful/dashboard/config", { params });
        },

        getFilterData(params) {
            return http.post("/restful/dashboard/filterdata", params);
        },

        getQuickFilters(params) {
            return http.get("/restful/dashboard/quickfilters", { params });
        },

        updateQuickFilters(params) {
            return http.post("/restful/dashboard/quickfilters", params);
        },

        saveQuickFilter(params) {
            return http.post("/restful/dashboard/quickfilter", params);
        },

        inventory({ signal, ...params }) {
            return http.post("/restful/dashboard/inventory", params, { signal });
        },

        inventoryMarketplaces(params) {
            return http.post("/restful/dashboard/inventory/marketplaces", params);
        },

        inventoryReport(params) {
            return http.post("/restful/dashboard/inventory/report", params);
        },

        ads({ signal, ...params }) {
            return http.post("/restful/dashboard/ads", params, { signal });
        },

        podding({ signal, ...params }) {
            return http.post("/restful/dashboard/podding", params, { signal });
        },

        adsMarketplaces(params) {
            return http.post("/restful/dashboard/ads/marketplaces", params);
        },

        advertisers({ signal, ...params }) {
            return http.post("/restful/dashboard/advertisers", params, { signal });
        },

        advertisersAds(params) {
            return http.post("/restful/dashboard/advertisers/ads", params);
        },

        campaigns({ signal, ...params }) {
            return http.post("/restful/dashboard/campaigns", params, { signal });
        },

        creatives({ signal, ...params }) {
            return http.post("/restful/dashboard/creatives", params, { signal });
        },

        accounts({ signal, ...params }) {
            return http.post("/restful/dashboard/accounts", params, { signal });
        },

        accountsReport(params) {
            return http.post("/restful/dashboard/accounts/report", params);
        },

        accountsUsers(params) {
            return http.get("/restful/dashboard/accounts/users", { params });
        },

        publishers({ signal, ...params }) {
            return http.post("/restful/dashboard/publishers", params, { signal });
        },

        buyerAnalytics(params) {
            return http.post("/restful/dashboard/buyeranalytics", params);
        },
    },

    accounts: {
        getCurrentAccount() {
            return http.get("/restful/settings/account");
        },

        getConnectedAccounts() {
            return http.get("/restful/settings/connected");
        },

        disconnectAccount(params) {
            return http.post("/restful/settings/connected/disconnect", params);
        },
    },

    users: {
        create(params) {
            return http.post("/restful/settings/users", params);
        },

        update(params) {
            return http.post("/restful/settings/users", params);
        },

        getSSOModulesById({ id }) {
            return http.get(`/restful/settings/users/modules/${id}`);
        },

        updateSSOModules(params) {
            return http.post("/restful/settings/users/modules", params);
        },

        getRolesById({ id }) {
            return http.get(`/restful/settings/users/roles/${id}`);
        },

        updateRoles(params) {
            return http.post("/restful/settings/users/roles", params);
        },

        updateProfile(params) {
            return http.post("/restful/settings/profile", params);
        },

        updateNotifications(params) {
            return http.post("/restful/settings/notifications", params);
        },

        updatePassword(params) {
            return http.post("/restful/settings/password", params);
        },

        uploadImage(params) {
            return http.post("/restful/settings/profile/upload", params);
        },

        getAll(params) {
            return http.get("/restful/settings/users", { params });
        },

        getUserById({ id }) {
            return http.get(`/restful/settings/users/${id}`);
        },

        getApiKey() {
            return http.get("/restful/settings/api/key");
        },

        rotateApiKey() {
            return http.get("/restful/settings/api/rotate");
        },
    },

    organization: {
        getAll(params) {
            return http.get("/restful/settings/organization", { params });
        },

        getAccountById({ id }) {
            return http.get(`/restful/settings/organization/${id}`);
        },

        createAccount(params) {
            return http.post("/restful/settings/organization", params);
        },

        updateAccount(params) {
            return http.post("/restful/settings/organization", params);
        },

        updateStatus(params) {
            return http.post("/restful/settings/organization/status", params);
        },

        getUsers(params) {
            return http.get("/restful/settings/organization/users", { params });
        },

        createUser(params) {
            return http.post("/restful/settings/organization/users", params);
        },

        updateUser(params) {
            return http.post("/restful/settings/organization/users", params);
        },
    },

    adLibrary: {
        getAll(params) {
            return http.get("/restful/quality/adlibrary", { params });
        },

        getByAdvertiser(params) {
            return http.get("/restful/quality/adlibrary/domain", { params });
        },

        getCacheEntry({ id }) {
            return http.get(`/restful/quality/adlibrary/${id}`);
        },

        searchDomain(params) {
            return http.get("/restful/quality/adlibrary/search", { params });
        },

        updateCacheEntry(params) {
            return http.post("/restful/quality/adlibrary/cache", params);
        },
    },

    advertisers: {
        getAll(params) {
            return http.get("/restful/quality/advertisers", { params });
        },

        getDownloadExcelUrl() {
            return http.defaults.baseURL + "/restful/quality/advertisers/excel";
        },

        getDownloadCSVUrl() {
            return http.defaults.baseURL + "/restful/quality/advertisers/csv";
        },
    },

    advertiserDomains: {
        getAll(params) {
            return http.get("/restful/quality/advertiserdomains", { params });
        },

        getAdvertiserDomainById({ id }) {
            return http.get(`/restful/quality/advertiserdomains/${id}`);
        },

        getContentCategories() {
            return http.get("/restful/quality/advertiserdomains/contentcategories");
        },

        update(params) {
            return http.post("/restful/quality/advertiserdomains", params);
        },
    },

    buyers: {
        getAll(params) {
            return http.get("/restful/quality/advertisers", { params });
        },

        getDownloadExcelUrl() {
            return http.defaults.baseURL + "/restful/quality/advertisers/excel";
        },

        getDownloadCSVUrl() {
            return http.defaults.baseURL + "/restful/quality/advertisers/csv";
        },
    },

    rules: {
        getAll(params) {
            return http.get("/restful/quality/rules", { params });
        },

        getRuleById({ id }) {
            return http.get(`/restful/quality/rules/${id}`);
        },

        getAdvertiserLists() {
            return http.get("/restful/quality/rules/advertiserlists");
        },

        getInventoriesById(params) {
            return http.get("/restful/quality/rules/inventories/", { params });
        },

        create(params) {
            return http.post("/restful/quality/rules", params);
        },

        update(params) {
            return http.post("/restful/quality/rules", params);
        },

        delete(data) {
            return http.delete("/restful/quality/rules", { data });
        },
    },

    creativeReviews: {
        getAll(params) {
            return http.get("/restful/quality/creativereview", { params });
        },

        changeStatus(params) {
            return http.post("/restful/quality/creativereview", params);
        },

        getDownloadExcelUrl() {
            return http.defaults.baseURL + "/restful/quality/creativereview/excel";
        },

        getDownloadCSVUrl() {
            return http.defaults.baseURL + "/restful/quality/creativereview/csv";
        },
    },

    adsTxt: {
        getAll(params) {
            return http.get("/restful/quality/adstxt", { params });
        },

        getDownloadExcelUrl() {
            return http.defaults.baseURL + "/restful/quality/adstxt/excel";
        },

        getDownloadCSVUrl() {
            return http.defaults.baseURL + "/restful/quality/adstxt/csv";
        },
    },

    inventory: {
        create(params) {
            return http.post("/restful/inventory/create", params);
        },

        update(params) {
            return http.post("/restful/inventory/update", params);
        },

        enable(data) {
            return http.post("/restful/inventory/status/enable", data);
        },

        disable(data) {
            return http.post("/restful/inventory/status/disable", data);
        },

        duplicate(data) {
            return http.post("/restful/inventory/clone", data);
        },

        delete(data) {
            return http.delete("/restful/inventory", { data });
        },

        getAll(params) {
            return http.get("/restful/inventory", { params });
        },

        getInventoryInfoById({ id }) {
            return http.get(`/restful/inventory/inventoryinfo/${id}`);
        },

        updateInventoryInfo(params) {
            return http.post("/restful/inventory/inventoryinfo", params);
        },

        getInventoryById({ id }) {
            return http.get(`/restful/inventory/${id}`);
        },

        getInventoryCreateData() {
            return http.get("/restful/inventory/create");
        },

        getConnections() {
            return http.get("/restful/inventory/connections");
        },

        getPlatforms() {
            return http.get("/restful/inventory/platforms");
        },

        getCRIDReviewRules({ id }) {
            return http.get(`/restful/inventory/cridreviewrules/${id}`);
        },

        getPublishers({ id }) {
            return http.get(`/restful/inventory/publishers/${id}`);
        },

        getAccounts(params) {
            return http.get("/restful/inventory/accounts", { params });
        },

        getMarketplaces() {
            return http.get("/restful/inventory/marketplaces");
        },

        getMarketplacesPod({ id }) {
            return http.get(`/restful/inventory/marketplacespod/${id}`);
        },

        getMarketplacesConfig({ id }) {
            return http.get(`/restful/inventory/marketplacesconfig/${id}`);
        },

        updateMarketplacesPod(params) {
            return http.post("/restful/inventory/marketplacespod", params);
        },

        updateMarketplaces(params) {
            return http.post("/restful/inventory/marketplaces", params);
        },

        stats() {
            return http.get("/restful/inventory/count");
        },
    },

    exchanges: {
        create(params) {
            return http.post("/restful/exchange", params);
        },

        update(params) {
            return http.post("/restful/exchange", params);
        },

        enable(data) {
            return http.post("/restful/exchange/status/enable", data);
        },

        disable(data) {
            return http.post("/restful/exchange/status/disable", data);
        },

        duplicate(data) {
            return http.post("/restful/exchange/clone", data);
        },

        delete(data) {
            return http.delete("/restful/exchange", { data });
        },

        getAll(params) {
            return http.get("/restful/exchange", { params });
        },

        getExchangeById({ id }) {
            return http.get(`/restful/exchange/${id}`);
        },

        getInventoryInfoById({ id }) {
            return http.get(`/restful/exchange/inventoryinfo/${id}`);
        },

        updateInventoryInfo(params) {
            return http.post("/restful/exchange/inventoryinfo", params);
        },

        getAccounts(params) {
            return http.get("/restful/exchange/accounts", { params });
        },

        getMarketplaces() {
            return http.get("/restful/exchange/marketplaces");
        },

        getMarketplacesConfig({ id }) {
            return http.get(`/restful/exchange/marketplacesconfig/${id}`);
        },

        updateMarketplaces(params) {
            return http.post("/restful/exchange/marketplaces", params);
        },

        getTrafficConfig({ id }) {
            return http.get(`/restful/exchange/trafficconfig/${id}`);
        },

        updateTraffic(params) {
            return http.post("/restful/exchange/traffic", params);
        },
        stats() {
            return http.get("/restful/exchange/count");
        },
    },

    appMapping: {
        create(params) {
            return http.post("/restful/appmapping/overview/create", params);
        },

        update(params) {
            return http.post("/restful/appmapping/overview/update", params);
        },

        delete(data) {
            return http.delete("/restful/appmapping/overview", { data });
        },

        getAll(params) {
            return http.get("/restful/appmapping/overview", { params });
        },

        getUnknown(params) {
            return http.get("/restful/appmapping/unknownapps", { params });
        },

        getOverviewAccounts() {
            return http.get("/restful/appmapping/overview/accounts");
        },

        getUnknownAccounts() {
            return http.get("/restful/appmapping/unknownapps/accounts");
        },

        getOverviewGenres() {
            return http.get("/restful/appmapping/overview/genres");
        },

        createItemsFromCSV(params) {
            return http.post("/restful/appmapping/overview/addcsv", params);
        },

        getUploadCSVUrl() {
            return http.defaults.baseURL + "/restful/appmapping/overview/upload";
        },

        getDownloadCSVUrl() {
            return http.defaults.baseURL + "/restful/appmapping/overview/csv";
        },
    },

    marketplaces: {
        create(params) {
            return http.post("/restful/marketplaces/owned", params);
        },

        update(params) {
            return http.post("/restful/marketplaces/owned", params);
        },

        enable(data) {
            return http.post("/restful/marketplaces/owned/status/enable", data);
        },

        disable(data) {
            return http.post("/restful/marketplaces/owned/status/disable", data);
        },

        duplicate(data) {
            return http.post("/restful/marketplaces/owned/clone", data);
        },

        delete(data) {
            return http.delete("/restful/marketplaces/owned", { data });
        },

        owned: {
            getAll(params) {
                return http.get("/restful/marketplaces/owned", { params });
            },

            getMarketplaceById({ id }) {
                return http.get(`/restful/marketplaces/owned/${id}`);
            },

            getMediaLists() {
                return http.get("/restful/marketplaces/owned/medialists");
            },

            getInventories(params) {
                return http.get("/restful/marketplaces/owned/inventory", { params });
            },

            getCuratedInventories(params) {
                return http.get("/restful/marketplaces/owned/curated", { params });
            },

            getAvailableInventories(params) {
                return http.get("/restful/marketplaces/owned/availableinventory", { params });
            },

            updateInventory(data) {
                return http.post("/restful/marketplaces/owned/inventory", data);
            },

            updateInventoryStatus(data) {
                return http.post("/restful/marketplaces/owned/inventorystatus", data);
            },

            updateInventoryMediaLists(data) {
                return http.post("/restful/marketplaces/owned/medialists", data);
            },

            deleteInventory(data) {
                return http.delete("/restful/marketplaces/owned/inventory", { data });
            },

            deleteAllInventory(data) {
                return http.delete("/restful/marketplaces/owned/allinventory", { data });
            },

            getCategories() {
                return http.get("/restful/marketplaces/owned/categories");
            },

            getAccounts() {
                return http.get("/restful/inventory/accounts");
            },

            getPriorities(params) {
                return http.get("/restful/marketplaces/owned/priorities", { params });
            },

            updatePriorities(data) {
                return http.post("/restful/marketplaces/owned/priorities", data);
            },

            getAds(params) {
                return http.get("/restful/marketplaces/owned/ads", { params });
            },

            assignAd(data) {
                return http.post("/restful/marketplaces/owned/assign", data);
            },

            getUploadUrl() {
                return http.defaults.baseURL + "/restful/marketplaces/owned/upload";
            },
        },
    },

    publishers: {
        create(params) {
            return http.post("/restful/publishers", params);
        },

        createUser(params) {
            return http.post("/restful/publishers/users", params);
        },

        update(params) {
            return http.post("/restful/publishers", params);
        },

        updateUser(params) {
            return http.post("/restful/publishers/users", params);
        },

        updateStatus(params) {
            return http.post("/restful/publishers/status", params);
        },

        updateInventoryPricing(params) {
            return http.post("/restful/publishers/inventory/pricing", params);
        },

        addInventory(params) {
            return http.post("/restful/publishers/inventory", params);
        },

        delete(data) {
            return http.delete("/restful/publishers", { data });
        },

        deleteInventories(data) {
            return http.delete("/restful/publishers/inventory", { data });
        },

        deleteUsers(data) {
            return http.delete("/restful/publishers/users", { data });
        },

        getAll(params) {
            return http.get("/restful/publishers", { params });
        },

        getPublisherById({ id }) {
            return http.get(`/restful/publishers/${id}`);
        },

        getInventories(params) {
            return http.get("/restful/publishers/inventory", { params });
        },

        getUsers(params) {
            return http.get("/restful/publishers/users", { params });
        },

        getAvailableInventories(params) {
            return http.get("/restful/publishers/inventory/available", { params });
        },
    },

    demandBuyers: {
        getAll(params) {
            return http.get("/restful/demand/buyers", { params });
        },

        getAvailableLists() {
            return http.get("/restful/demand/buyers/lists");
        },

        getAccountManagers() {
            return http.get("/restful/demand/buyers/managers");
        },

        getBuyerById({ id }) {
            return http.get(`/restful/demand/buyers/${id}`);
        },

        getAdsById(params) {
            return http.get("/restful/demand/buyers/ads", { params });
        },

        getSeatsById(params) {
            return http.get("/restful/demand/buyers/seats", { params });
        },

        updateSeat(params) {
            return http.post("/restful/demand/buyers/seats", params);
        },

        deleteSeats(data) {
            return http.delete("/restful/demand/buyers/seats", { data });
        },

        create(params) {
            return http.post("/restful/demand/buyers", params);
        },

        update(params) {
            return http.post("/restful/demand/buyers", params);
        },

        delete(data) {
            return http.delete("/restful/demand/buyers", { data });
        },

        getUploadUrl() {
            return http.defaults.baseURL + "/restful/demand/buyers/upload";
        },
    },

    dsp: {
        getAll(params) {
            return http.get("/restful/demand/dsp", { params });
        },

        getAccounts() {
            return http.get("/restful/demand/dsp/accounts");
        },

        getDSPSyncList() {
            return http.get("/restful/demand/dsp/synclist");
        },

        getDspById({ id }) {
            return http.get(`/restful/demand/dsp/${id}`);
        },

        getAdsById(params) {
            return http.get("/restful/demand/dsp/ads", { params });
        },

        getSeatsById(params) {
            return http.get("/restful/demand/dsp/seats", { params });
        },

        updateSeat(params) {
            return http.post("/restful/demand/dsp/seats", params);
        },

        deleteSeats(data) {
            return http.delete("/restful/demand/dsp/seats", { data });
        },

        create(params) {
            return http.post("/restful/demand/dsp", params);
        },

        update(params) {
            return http.post("/restful/demand/dsp", params);
        },

        delete(data) {
            return http.delete("/restful/demand/dsp", { data });
        },

        getUploadUrl() {
            return http.defaults.baseURL + "/restful/demand/dsp/upload";
        },
    },

    campaigns: {
        create(params) {
            return http.post("/restful/demand/campaigns", params);
        },

        getAll(params) {
            return http.get("/restful/demand/campaigns", { params });
        },

        getCampaignById({ id }) {
            return http.get(`/restful/demand/campaigns/${id}`);
        },

        getCampaignAdsById(params) {
            return http.get("/restful/demand/campaigns/ads", { params });
        },

        getAccounts() {
            return http.get("/restful/demand/campaigns/accounts");
        },

        update(params) {
            return http.post("/restful/demand/campaigns", params);
        },

        enable(params) {
            return http.post("/restful/demand/campaigns/status/enable", params);
        },

        disable(params) {
            return http.post("/restful/demand/campaigns/status/disable", params);
        },

        delete(data) {
            return http.delete("/restful/demand/campaigns", { data });
        },

        stats() {
            return http.get("/restful/demand/campaigns/count");
        },
    },

    ads: {
        create(params) {
            return http.post("/restful/demand/ads", params);
        },

        getAll(params) {
            return http.get("/restful/demand/ads", { params });
        },

        getAdById({ id }) {
            return http.get(`/restful/demand/ads/${id}`);
        },

        getAdDeliveryById({ id }) {
            return http.get(`/restful/demand/ads/delivery/${id}`);
        },

        updateAdDelivery(params) {
            return http.post("/restful/demand/ads/delivery", params);
        },

        getAdTargetingById({ id }) {
            return http.get(`/restful/demand/ads/targeting/${id}`);
        },

        updateAdTargeting(params) {
            return http.post("/restful/demand/ads/targeting", params);
        },

        getAdContentMediaList() {
            return http.get("/restful/demand/ads/contentmedialist");
        },

        getContentItems(params) {
            return http.get("/restful/demand/ads/contentitems", { params });
        },

        getAudinceSegments() {
            return http.get("/restful/demand/ads/segments");
        },

        getAdKpiTargetingById({ id }) {
            return http.get(`/restful/demand/ads/kpitargeting/${id}`);
        },

        updateAdKpiTargeting(params) {
            return http.post("/restful/demand/ads/kpitargeting", params);
        },

        getAdOptionalKeysById({ id }) {
            return http.get(`/restful/demand/ads/customkeys/${id}`);
        },

        getAdOptionalKeysAdvertisers({ key_id }) {
            return http.get(`/restful/demand/ads/advertisers/${key_id}`);
        },

        getAdOptionalKeysCustom({ type }) {
            return http.get(`/restful/demand/ads/customparams/${type}`);
        },

        updateAdOptionalKeys(params) {
            return http.post("/restful/demand/ads/customkeys", params);
        },

        getAdInventoryById({ id }) {
            return http.get(`/restful/demand/ads/inventory/${id}`);
        },

        getAdInventories(params) {
            return http.post("/restful/demand/ads/inventories", params);
        },

        updateAdInventory(params) {
            return http.post("/restful/demand/ads/inventory", params);
        },

        getAdSettingsById({ id }) {
            return http.get(`/restful/demand/ads/settings/${id}`);
        },

        getAdClearviewAccounts({ account_id }) {
            return http.get(`/restful/demand/ads/clearvuaccounts/${account_id}`);
        },

        getAdClearviewDeals({ ad_id, account_id }) {
            return http.get(`/restful/demand/ads/clearvudeals/${ad_id}/${account_id}`);
        },

        updateAdSettings(params) {
            return http.post("/restful/demand/ads/settings", params);
        },

        getAdTrackingById({ id }) {
            return http.get(`/restful/demand/ads/tracking/${id}`);
        },

        updateAdTracking(params) {
            return http.post("/restful/demand/ads/tracking", params);
        },

        update(params) {
            return http.post("/restful/demand/ads", params);
        },

        enable(params) {
            return http.post("/restful/demand/ads/status/enable", params);
        },

        disable(params) {
            return http.post("/restful/demand/ads/status/disable", params);
        },

        duplicate(params) {
            return http.post("/restful/demand/ads/clone", params);
        },

        delete(data) {
            return http.delete("/restful/demand/ads/", { data });
        },

        getAccounts() {
            return http.get("/restful/demand/ads/accounts");
        },

        getCampaigns({ id }) {
            return http.get(`/restful/demand/ads/campaigns/${id}`);
        },

        getBuyers(params) {
            return http.get("/restful/demand/ads/buyers", { params });
        },

        getDspConnections(params) {
            return http.get("/restful/demand/ads/dspconnections", { params });
        },

        getDspDeals({ id }) {
            return http.get(`/restful/demand/ads/dspdeals/${id}`);
        },

        getDealSeats({ id }) {
            return http.get(`/restful/demand/ads/dealseats/${id}`);
        },

        getUserSyncs() {
            return http.get("/restful/demand/ads/usersyncs");
        },

        getCreatives(params) {
            return http.get("/restful/demand/ads/creatives", { params });
        },

        geoNames(params) {
            return http.get("/restful/geonames", { params });
        },

        customAutocomplete(params) {
            return http.get("/restful/demand/ads/customautocomplete", { params });
        },

        getUniqueDealId() {
            return http.get("/restful/demand/ads/uniquedealid");
        },

        stats() {
            return http.get("/restful/demand/ads/count");
        },
    },

    deals: {
        getAll(params) {
            return http.get("/restful/demand/deals", { params });
        },
    },

    creatives: {
        getAll(params) {
            return http.get("/restful/demand/creatives", { params });
        },

        getCreativeById({ id }) {
            return http.get(`/restful/demand/creatives/${id}`);
        },

        create(params) {
            return http.post("/restful/demand/creatives", params);
        },

        update(params) {
            return http.post("/restful/demand/creatives", params);
        },

        delete(data) {
            return http.delete("/restful/demand/creatives", { data });
        },

        enable(params) {
            return http.post("/restful/demand/creatives/status/enable", params);
        },

        disable(params) {
            return http.post("/restful/demand/creatives/status/disable", params);
        },

        getAccounts() {
            return http.get("/restful/demand/creatives/accounts");
        },

        upload(params) {
            return http.post("/restful/demand/creatives/upload", params);
        },
    },

    adomains: {
        getAll(params) {
            return http.get("/restful/demand/advertisers", { params });
        },

        create(params) {
            return http.post("/restful/demand/advertisers", params);
        },

        update(params) {
            return http.post("/restful/demand/advertisers", params);
        },

        delete(data) {
            return http.delete("/restful/demand/advertisers", { data });
        },
    },

    mediaList: {
        getAll(params) {
            return http.get("/restful/demand/medialists", { params });
        },

        getMediaListById({ id }) {
            return http.get(`/restful/demand/medialists/${id}`);
        },

        getMediaListItemsById(params) {
            return http.get("/restful/demand/medialists/items", { params });
        },

        create(params) {
            return http.post("/restful/demand/medialists", params);
        },

        createItems(params) {
            return http.post("/restful/demand/medialists/items", params);
        },

        createItemsFromCSV(params) {
            return http.post("/restful/demand/medialists/file", params);
        },

        update(params) {
            return http.post("/restful/demand/medialists", params);
        },

        duplicate(params) {
            return http.post("/restful/demand/medialists/clone", params);
        },

        delete(data) {
            return http.delete("/restful/demand/medialists", { data });
        },

        deleteItems(data) {
            return http.delete("/restful/demand/medialists/items", { data });
        },

        deleteAllItems(data) {
            return http.delete("/restful/demand/medialists/all", { data });
        },

        deleteItemsFromCSV(data) {
            return http.delete("/restful/demand/medialists/file", { data });
        },

        getUploadCSVUrl() {
            return http.defaults.baseURL + "/restful/demand/medialists/upload";
        },

        getDownloadExcelUrl() {
            return http.defaults.baseURL + "/restful/demand/medialists/excel";
        },

        getDownloadCSVUrl() {
            return http.defaults.baseURL + "/restful/demand/medialists/csv";
        },
    },

    locationList: {
        getAll(params) {
            return http.get("/restful/demand/locationlists", { params });
        },

        getLocationListById({ id }) {
            return http.get(`/restful/demand/locationlists/${id}`);
        },

        getLocationListItemsById(params) {
            return http.get("/restful/demand/locationlists/items", { params });
        },

        create(params) {
            return http.post("/restful/demand/locationlists", params);
        },

        createItems(params) {
            return http.post("/restful/demand/locationlists/items", params);
        },

        createItemsFromCSV(params) {
            return http.post("/restful/demand/locationlists/file", params);
        },

        update(params) {
            return http.post("/restful/demand/locationlists", params);
        },

        duplicate(params) {
            return http.post("/restful/demand/locationlists/clone", params);
        },

        delete(data) {
            return http.delete("/restful/demand/locationlists", { data });
        },

        deleteItems(data) {
            return http.delete("/restful/demand/locationlists/items", { data });
        },

        deleteAllItems(data) {
            return http.delete("/restful/demand/locationlists/all", { data });
        },

        getUploadCSVUrl() {
            return http.defaults.baseURL + "/restful/demand/locationlists/upload";
        },

        getDownloadExcelUrl() {
            return http.defaults.baseURL + "/restful/demand/locationlists/excel";
        },

        getDownloadCSVUrl() {
            return http.defaults.baseURL + "/restful/demand/locationlists/csv";
        },
    },

    advertiserList: {
        getAll(params) {
            return http.get("/restful/demand/advertiserlists", { params });
        },

        getAdvertiserListById({ id }) {
            return http.get(`/restful/demand/advertiserlists/${id}`);
        },

        getAdvertiserListItemsById(params) {
            return http.get("/restful/demand/advertiserlists/items", { params });
        },

        create(params) {
            return http.post("/restful/demand/advertiserlists", params);
        },

        createItems(params) {
            return http.post("/restful/demand/advertiserlists/items", params);
        },

        createItemsFromCSV(params) {
            return http.post("/restful/demand/advertiserlists/file", params);
        },

        update(params) {
            return http.post("/restful/demand/advertiserlists", params);
        },

        duplicate(params) {
            return http.post("/restful/demand/advertiserlists/clone", params);
        },

        delete(data) {
            return http.delete("/restful/demand/advertiserlists", { data });
        },

        deleteItems(data) {
            return http.delete("/restful/demand/advertiserlists/items", { data });
        },

        deleteAllItems(data) {
            return http.delete("/restful/demand/advertiserlists/all", { data });
        },

        getUploadCSVUrl() {
            return http.defaults.baseURL + "/restful/demand/advertiserlists/upload";
        },

        getDownloadExcelUrl() {
            return http.defaults.baseURL + "/restful/demand/advertiserlists/excel";
        },

        getDownloadCSVUrl() {
            return http.defaults.baseURL + "/restful/demand/advertiserlists/csv";
        },
    },

    contentList: {
        getAll(params) {
            return http.get("/restful/demand/contentlists", { params });
        },

        getContentListById({ id }) {
            return http.get(`/restful/demand/contentlists/${id}`);
        },

        getGenres() {
            return http.get("/restful/demand/contentlists/genres");
        },

        getNetworks() {
            return http.get("/restful/demand/contentlists/networks");
        },

        getPrograms() {
            return http.get("/restful/demand/contentlists/programs");
        },

        create(params) {
            return http.post("/restful/demand/contentlists", params);
        },

        update(params) {
            return http.post("/restful/demand/contentlists", params);
        },

        updateManage(params) {
            return http.post("/restful/demand/contentlists/manage", params);
        },

        duplicate(params) {
            return http.post("/restful/demand/contentlists/clone", params);
        },

        delete(data) {
            return http.delete("/restful/demand/contentlists", { data });
        },
    },

    accountList: {
        getAll(params) {
            return http.get("/restful/demand/accountlists", { params });
        },

        getAccountListById({ id }) {
            return http.get(`/restful/demand/accountlists/${id}`);
        },

        getAccountListItemsById(params) {
            return http.get("/restful/demand/accountlists/items", { params });
        },

        getUnassignedAccounts(params) {
            return http.get("/restful/demand/accountlists/unassigned", { params });
        },

        create(params) {
            return http.post("/restful/demand/accountlists", params);
        },

        createItems(params) {
            return http.post("/restful/demand/accountlists/items", params);
        },

        update(params) {
            return http.post("/restful/demand/accountlists", params);
        },

        duplicate(params) {
            return http.post("/restful/demand/accountlists/clone", params);
        },

        delete(data) {
            return http.delete("/restful/demand/accountlists", { data });
        },

        deleteItems(data) {
            return http.delete("/restful/demand/accountlists/items", { data });
        },

        deleteAllItems(data) {
            return http.delete("/restful/demand/accountlists/all", { data });
        },

        getDownloadExcelUrl() {
            return http.defaults.baseURL + "/restful/demand/accountlists/excel";
        },

        getDownloadCSVUrl() {
            return http.defaults.baseURL + "/restful/demand/accountlists/csv";
        },
    },

    keys: {
        getAll(params) {
            return http.get("/restful/demand/keys", { params });
        },

        create(params) {
            return http.post("/restful/demand/keys", params);
        },

        update(params) {
            return http.post("/restful/demand/keys", params);
        },

        delete(data) {
            return http.delete("/restful/demand/keys", { data });
        },
    },

    audienceSegments: {
        getAll(params) {
            return http.get("/restful/demand/audiencesegments", { params });
        },

        getAudienceSegmentById({ id }) {
            return http.get(`/restful/demand/audiencesegments/${id}`);
        },

        update(params) {
            return http.post("/restful/demand/audiencesegments", params);
        },

        updatePrice(params) {
            return http.post("/restful/demand/audiencesegments/price", params);
        },

        delete(data) {
            return http.delete("/restful/demand/audiencesegments", { data });
        },

        getProvidersList() {
            return http.get("/restful/demand/audiencesegments/providers");
        },
    },

    userSync: {
        getAll(params) {
            return http.get("/restful/demand/usersync", { params });
        },

        getUserSyncById({ id }) {
            return http.get(`/restful/demand/usersync/${id}`);
        },

        create(params) {
            return http.post("/restful/demand/usersync", params);
        },

        update(params) {
            return http.post("/restful/demand/usersync", params);
        },

        delete(data) {
            return http.delete("/restful/demand/usersync", { data });
        },
    },

    countries: {
        getAll(params) {
            return http.get("/restful/countries/list", { params });
        },
    },

    messages: {
        getAll(params) {
            return http.get("/restful/messages", { params });
        },

        create(params) {
            return http.post("/restful/messages", params);
        },

        count() {
            return http.get("/restful/messages/count");
        },

        read(params) {
            return http.post("/restful/messages/read", params);
        },

        unread(params) {
            return http.post("/restful/messages/unread", params);
        },

        delete(data) {
            return http.delete("/restful/messages", { data });
        },

        getUsers() {
            return http.get("/restful/messages/users");
        },
    },

    monitor: {
        getAccounts(params) {
            return http.get("/restful/monitor/accounts", { params });
        },

        getAds(params) {
            return http.get("/restful/monitor/ads", { params });
        },

        getInventory(params) {
            return http.get("/restful/monitor/inventory", { params });
        },

        getPlatforms() {
            return http.get("/restful/monitor/platforms");
        },

        getConfig() {
            return http.get("/restful/monitor/config");
        },

        getLog(params) {
            return http.get("/restful/monitor/log", { params });
        },
    },

    reports: {
        getCanned() {
            return http.get("/restful/reports/canned");
        },

        getOwned(params) {
            return http.get("/restful/reports/owned", { params });
        },

        getShared(params) {
            return http.get("/restful/reports/public", { params });
        },

        getCannedReportById({ id }) {
            return http.get(`/restful/reports/canned/${id}`);
        },

        getCustomReportById({ id }) {
            return http.get(`/restful/reports/${id}`);
        },

        getReportFilters() {
            return http.get("/restful/reports/filters");
        },

        getReportDimensions() {
            return http.get("/restful/reports/dimensions");
        },

        getReportMetricsFilters() {
            return http.get("/restful/reports/metricsfilters");
        },

        getReportMetrics() {
            return http.get("/restful/reports/metrics");
        },

        getAccounts(params) {
            return http.get("/restful/reports/accounts", { params });
        },

        getReportCreativeAdm({ id }) {
            return http.get(`/restful/reports/adm/${id}`);
        },

        getReportCreativeCache({ id }) {
            return http.get(`/restful/reports/cache/${id}`);
        },

        udpateReportCreativeCache(params) {
            return http.post("/restful/reports/updatecache", params);
        },

        runReport(params) {
            return http.post("/restful/reports/run", params);
        },

        runCannedReport(params) {
            return http.post("/restful/reports/canned", params);
        },

        create(params) {
            return http.post("/restful/reports", params);
        },

        update(params) {
            return http.post("/restful/reports", params);
        },

        updateSchedule(params) {
            return http.post("/restful/reports/schedule", params);
        },

        duplicate(params) {
            return http.post("/restful/reports/clone", params);
        },

        delete(data) {
            return http.delete("/restful/reports", { data });
        },

        getDownloadExcelUrl() {
            return http.defaults.baseURL + "/restful/reports/excel";
        },

        getDownloadCSVUrl() {
            return http.defaults.baseURL + "/restful/reports/csv";
        },

        getDownloadCannedExcelUrl() {
            return http.defaults.baseURL + "/restful/reports/cannedexcel";
        },

        getDownloadCannedCSVUrl() {
            return http.defaults.baseURL + "/restful/reports/cannedcsv";
        },
    },

    changelog: {
        getAll(params) {
            return http.get("/restful/changelog", { params });
        },
    },

    billing: {
        getInvoices(params) {
            return http.get("/restful/billing/invoices", { params });
        },

        getStatements(params) {
            return http.get("/restful/billing/statements", { params });
        },

        getInvoicesDownloadExcelUrl() {
            return http.defaults.baseURL + "/restful/billing/invoicesexcel";
        },

        getStatementsDownloadExcelUrl() {
            return http.defaults.baseURL + "/restful/billing/statementsexcel";
        },
    },
};
