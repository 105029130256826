import { createEnum } from "../utils/enum-util";

export const CustomPermission = createEnum({
    ADPERFORMANCE: {
        key: "custom.adperformance.read",
        name: "Ad Performance",
    },
    ANALYTICS: {
        key: "custom.analytics.read",
        name: "Analytics",
    },
    TRAFFICQUALITY: {
        key: "custom.trafficquality.read",
        name: "Traffic Quiality",
    },
    CREATIVEREVIEW: {
        key: "custom.creativereview.read",
        name: "Creative Review",
    },
    QUALITY_BUYERS: {
        key: "custom.quality_buyers.read",
        name: "Quality Buyers",
    },
    AUDIENCE: {
        key: "custom.audience.read",
        name: "Audience",
    },
    PUBLISHER_CREATIVEREVIEW: {
        key: "custom.publisher_creativereview.read",
        name: "Publisher Creative Review",
    },
    DEALS: {
        key: "custom.deals.read",
        name: "Deals",
    },
    DSP: {
        key: "custom.dsp.read",
        name: "DSP",
    },
    PIPELINE: {
        key: "custom.pipeline.read",
        name: "Pipeline",
    },
    NOTIFICATION: {
        key: "custom.notification.read",
        name: "Notification",
    },
    MESSAGES: {
        key: "custom.messages.read",
        name: "Messages",
    },
    MONITOR: {
        key: "custom.monitor.read",
        name: "Real Time Monitor",
    },
    MANAGER: {
        key: "custom.manager.edit",
        name: "Account Manager",
    },
    ORGANIZATION: {
        key: "custom.organization.read",
        name: "Organization",
    },
    CREATIVE_ADI: {
        key: "custom.creative_adi.read",
        name: "Creative ADI",
    },
    PODDING: {
        key: "custom.podding.read",
        name: "Podding",
    },
});
