import { createEnum } from "../utils/enum-util";

export const AudiencePlatform = createEnum({
    DESKTOP_WEB: {
        name: "Desktop",
    },
    MOB_WEB: {
        name: "Mobile",
    },
    CONNECTED_TV: {
        name: "Connected TV",
    },
});
