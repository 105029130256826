import { createEnum } from "../utils/enum-util";

export const AdGoalPaceType = createEnum({
    FAST_AS_POSSIBLE: {
        name: "Fast As Possible",
    },
    EVENLY: {
        name: "Evenly",
    },
});
