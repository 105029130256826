import dayjs from "dayjs";

import { createEnum } from "../utils/enum-util";

export const DateRange = createEnum({
    TODAY: {
        key: "today",
        name: "Today",
        dates: [dayjs(), dayjs()],
    },
    YESTERDAY: {
        key: "yesterday",
        name: "Yesterday",
        dates: [dayjs().subtract(1, "days"), dayjs().subtract(1, "days")],
    },
    LAST_7_DAYS: {
        key: "7_days",
        name: "Last 7 Days",
        dates: [dayjs().subtract(7, "days"), dayjs().subtract(1, "days")],
    },
    LAST_14_DAYS: {
        key: "14_days",
        name: "Last 14 Days",
        dates: [dayjs().subtract(14, "days"), dayjs().subtract(1, "days")],
    },
    LAST_30_DAYS: {
        key: "30_days",
        name: "Last 30 Days",
        dates: [dayjs().subtract(30, "days"), dayjs().subtract(1, "days")],
    },
    MONTH_TO_DATE: {
        key: "month_to_date",
        name: "Month To Date",
        dates: [dayjs().startOf("month").startOf("day"), dayjs()],
    },
    PREVIOUS_MONTH: {
        key: "prev_month",
        name: "Previous Month",
        dates: [
            dayjs().subtract(1, "month").startOf("month").startOf("day"),
            dayjs().subtract(1, "month").endOf("month").endOf("day"),
        ],
    },
    CUSTOM: {
        key: "custom",
        name: "Custom Dates",
        dates: null,
    },
});
