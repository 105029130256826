import { createEnum } from "../utils/enum-util";

export const MarketplaceInventoryStatus = createEnum({
    ENABLE: {
        name: "Active",
    },
    DISABLE: {
        name: "Paused",
    },
    REVOKED: {
        name: "Revoked",
    },
});
