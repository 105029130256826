import { useMemo } from "react";
import { useLocalStorage } from "@beachfront/ui/hooks";

import { USER_STORAGE_KEY } from "../auth/auth-service";
import { AccountType } from "../enums";

/**
 * Uses the currently logged in user.
 *
 * @returns {Object}
 */
export function useCurrentUser() {
    const [user, setCurrentUser] = useLocalStorage(USER_STORAGE_KEY);
    const currentUser = useMemo(
        () => ({
            ...user,
            isSuper: user?.account_type.includes(AccountType.SUPER.key),
        }),
        [user]
    );
    return [currentUser, setCurrentUser];
}
