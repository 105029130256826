import dayjs from "dayjs";

import { isObject, isArray } from "./type-util";

/**
 * Parses data fields based on a given schema.
 *
 * @param   {*}      data
 * @param   {Object} schema
 * @returns {*}
 */
export function parseFields(data, schema) {
    if (!schema) {
        return;
    }

    const items = isArray(data) ? data : [data];

    for (let [field, type] of Object.entries(schema)) {
        if (type === "date") {
            items.forEach((item) => {
                item[field] = item[field] ? dayjs(item[field]) : dayjs(null);
            });
        } else if (type === "unix") {
            items.forEach((item) => {
                item[field] = item[field] ? dayjs.unix(item[field]) : dayjs(null);
            });
        } else if (type === "bool") {
            items.forEach((item) => {
                item[field] = Boolean(item[field]);
            });
        } else if (type === "json") {
            items.forEach((item) => {
                try {
                    item[field] = JSON.parse(item[field]);
                } catch (e) {} // eslint-disable-line no-empty
            });
        } else if (isObject(type)) {
            items.forEach((item) => {
                parseFields(item[field], type);
            });
        }
    }

    return data;
}
