import {
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    Route,
    Navigate,
    useParams,
    generatePath,
} from "react-router-dom";
import { Spinner } from "@beachfront/ui";

import { useCurrentUser } from "../../hooks";
import { CustomPermission } from "../../enums";
import { FatalError } from "../error-handlers";
import RequireAuth from "../require-auth";
import LoginPage from "../../pages/login/login-page";
import ForgotPasswordPage from "../../pages/forgot-password/forgot-password-page";
import ResetPasswordPage from "../../pages/reset-password/reset-password-page";
import RegisterPage from "../../pages/register/register-page";
import LogoutPage from "../../pages/logout/logout-page";
import PageForbidden from "../../pages/forbidden/page-forbidden";
import PageNotFound from "../../pages/not-found/page-not-found";
import PageInfo from "../../pages/info/page-info";
import Permission from "../permission";

import { lazyRetry } from "./lazy-retry";
import ProtectedLayout from "./protected-layout";

const OverviewSection = lazyRetry(() => import("../../pages/overview/overview-section"));
const DashboardSection = lazyRetry(() => import("../../pages/dashboard/dashboard-section"));
const QualitySection = lazyRetry(() => import("../../pages/quality/quality-section"));
const DemandSection = lazyRetry(() => import("../../pages/demand/demand-section"));
const InventorySection = lazyRetry(() => import("../../pages/inventory/inventory-section"));
const ExchangeSection = lazyRetry(() => import("../../pages/exchanges/exchange-section"));
const MarketplaceSection = lazyRetry(() => import("../../pages/marketplaces/marketplace-section"));
const AppMappingSection = lazyRetry(() => import("../../pages/app-mapping/app-mapping-section"));
const PublisherSection = lazyRetry(() => import("../../pages/publishers/publisher-section"));
const ReportingSection = lazyRetry(() => import("../../pages/reporting/reporting-section"));
const MonitorSection = lazyRetry(() => import("../../pages/monitor/monitor-section"));
const ChangelogSection = lazyRetry(() => import("../../pages/changelog/changelog-section"));
const MessagesSection = lazyRetry(() => import("../../pages/messages/messages-section"));
const SettingsSection = lazyRetry(() => import("../../pages/settings/settings-section"));
const BillingSection = lazyRetry(() => import("../../pages/billing/billing-section"));

const createRouter = (user) => {
    return createBrowserRouter(
        createRoutesFromElements(
            <Route errorElement={<FatalError />}>
                <Route path="/" element={<LoginPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
                <Route path="/register/:companyId/:usersId" element={<RegisterPage />} />
                <Route path="/logout" element={<LogoutPage />} />
                <Route path="/info" element={<PageInfo />} />
                <Route path="/*" element={<ProtectedLayout />}>
                    <Route
                        path="campaigns/ad/:id/*"
                        element={<Redirect to="/demand/ads/:id/*" replace />}
                    />
                    <Route
                        path="users/campaign/:id/*"
                        element={<Redirect to="/demand/campaigns/:id/*" replace />}
                    />
                    <Route
                        path="en/users/report/:id/*"
                        element={<Redirect to="/reporting/custom/:id/*" replace />}
                    />
                    <Route
                        path="overview/*"
                        element={<OverviewSection />}
                        errorElement={<FatalError />}
                    />
                    <Route
                        path="dashboard/*"
                        element={<DashboardSection />}
                        errorElement={<FatalError />}
                    />
                    <Route
                        path="quality/*"
                        element={<QualitySection />}
                        errorElement={<FatalError />}
                    />
                    <Route
                        path="demand/*"
                        element={<DemandSection />}
                        errorElement={<FatalError />}
                    />
                    <Route
                        path="inventory/*"
                        element={<InventorySection />}
                        errorElement={<FatalError />}
                    />
                    <Route
                        path="exchanges/*"
                        element={<ExchangeSection />}
                        errorElement={<FatalError />}
                    />
                    <Route
                        path="marketplaces/*"
                        element={<MarketplaceSection />}
                        errorElement={<FatalError />}
                    />
                    <Route
                        path="app-mapping/*"
                        element={<AppMappingSection />}
                        errorElement={<FatalError />}
                    />
                    {!user.isOrganizationAdmin && (
                        <Route
                            path="publishers/*"
                            element={
                                <RequireAuth
                                    require={(user) =>
                                        !Permission.hasAccess(
                                            user,
                                            CustomPermission.ORGANIZATION.key,
                                        )
                                    }
                                >
                                    <PublisherSection />
                                </RequireAuth>
                            }
                            errorElement={<FatalError />}
                        />
                    )}
                    <Route
                        path="reporting/*"
                        element={<ReportingSection />}
                        errorElement={<FatalError />}
                    />
                    <Route
                        path="monitor/*"
                        element={<MonitorSection />}
                        errorElement={<FatalError />}
                    />
                    <Route
                        path="changelog/*"
                        element={<ChangelogSection />}
                        errorElement={<FatalError />}
                    />
                    <Route
                        path="messages/*"
                        element={
                            <RequireAuth require={CustomPermission.MESSAGES.key}>
                                <MessagesSection />
                            </RequireAuth>
                        }
                        errorElement={<FatalError />}
                    />
                    <Route
                        path="settings/*"
                        element={<SettingsSection />}
                        errorElement={<FatalError />}
                    />
                    <Route
                        path="billing/*"
                        element={
                            <RequireAuth
                                require={(user) =>
                                    Permission.hasAccess(user, "users.settings_billing.read") &&
                                    (!user.isConsolidatedBilling || user.isOrganizationAdmin)
                                }
                            >
                                <BillingSection />
                            </RequireAuth>
                        }
                        errorElement={<FatalError />}
                    />
                    <Route path="forbidden" element={<PageForbidden />} />
                    <Route path="*" element={<PageNotFound />} />
                </Route>
            </Route>,
        ),
    );
};

function Redirect({ to, replace, state }) {
    const params = useParams();
    const redirectWithParams = generatePath(to, params);
    return <Navigate to={redirectWithParams} replace={replace} state={state} />;
}

function AppRouter() {
    const [user] = useCurrentUser();
    const router = createRouter(user);
    return <RouterProvider router={router} fallbackElement={<Spinner size="large" mask />} />;
}

export default AppRouter;
