import { createEnum } from "../utils/enum-util";

export const LocationListType = createEnum({
    ZIP: {
        name: "Zip Codes",
    },
    DMA: {
        name: "Metro Codes",
    },
    COUNTRY: {
        name: "Country Codes",
    },
});
