import { lazy } from "react";

const REFRESHED_STORAGE_KEY = "lazy-retry-refreshed";

export const lazyRetry = (importComponent) => {
    return lazy(() => {
        return new Promise((resolve, reject) => {
            const hasRefreshed = !!sessionStorage.getItem(REFRESHED_STORAGE_KEY);
            importComponent()
                .then((component) => {
                    sessionStorage.removeItem(REFRESHED_STORAGE_KEY);
                    resolve(component);
                })
                .catch((error) => {
                    if (!hasRefreshed) {
                        sessionStorage.setItem(REFRESHED_STORAGE_KEY, "true");
                        return window.location.reload();
                    }
                    reject(error);
                });
        });
    });
};
