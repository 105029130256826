import { createEnum } from "../utils/enum-util";

export const MonitorRtlogMode = createEnum({
    BOTH: {
        name: "Requests and Resonses",
    },
    REQUESTS: {
        name: "Requests",
    },
    RESPONSES: {
        name: "Responses",
    },
    REQ_RESP: {
        name: "Request/Response",
    },
    OUTBIDS_BOTH: {
        name: "Outgoing Bids",
    },
    OUTBIDS_REQUESTS: {
        name: "Requests",
    },
    OUTBIDS_RESPONSES: {
        name: "Responses",
    },
    OUTBIDS_REQ_RESP: {
        name: "Request/Response",
    },
});
