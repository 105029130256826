import { createEnum } from "../utils/enum-util";

export const GridView = createEnum({
    ALL: {
        name: "All",
    },
    SELECTED: {
        name: "Selected",
    },
    UNSELECTED: {
        name: "Unselected",
    },
});
