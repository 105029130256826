import { createEnum } from "../utils/enum-util";

export const Dma = createEnum({
    500: {
        name: "500 - Portland-Auburn, ME",
        code: "500",
    },
    501: {
        name: "501 - New York, NY",
        code: "501",
    },
    502: {
        name: "502 - Binghamton, NY",
        code: "502",
    },
    503: {
        name: "503 - Macon, GA",
        code: "503",
    },
    504: {
        name: "504 - Philadelphia, PA",
        code: "504",
    },
    505: {
        name: "505 - Detroit, MI",
        code: "505",
    },
    506: {
        name: "506 - Boston, MA-Manchester, NH",
        code: "506",
    },
    507: {
        name: "507 - Savannah, GA",
        code: "507",
    },
    508: {
        name: "508 - Pittsburgh, PA",
        code: "508",
    },
    509: {
        name: "509 - Ft. Wayne, IN",
        code: "509",
    },
    510: {
        name: "510 - Cleveland-Akron (Canton), OH",
        code: "510",
    },
    511: {
        name: "511 - Washington, DC (Hagerstown, MD)",
        code: "511",
    },
    512: {
        name: "512 - Baltimore, MD",
        code: "512",
    },
    513: {
        name: "513 - Flint-Saginaw-Bay City, MI",
        code: "513",
    },
    514: {
        name: "514 - Buffalo, NY",
        code: "514",
    },
    515: {
        name: "515 - Cincinnati, OH",
        code: "515",
    },
    516: {
        name: "516 - Erie, PA",
        code: "516",
    },
    517: {
        name: "517 - Charlotte, NC",
        code: "517",
    },
    518: {
        name: "518 - Greensboro-Winston Salem, NC",
        code: "518",
    },
    519: {
        name: "519 - Charleston, SC",
        code: "519",
    },
    520: {
        name: "520 - Augusta, GA",
        code: "520",
    },
    521: {
        name: "521 - Providence, RI-New Bedford, MA",
        code: "521",
    },
    522: {
        name: "522 - Columbus, GA",
        code: "522",
    },
    523: {
        name: "523 - Burlington, VT-Plattsburgh, NY",
        code: "523",
    },
    524: {
        name: "524 - Atlanta, GA",
        code: "524",
    },
    525: {
        name: "525 - Albany, GA",
        code: "525",
    },
    526: {
        name: "526 - Utica, NY",
        code: "526",
    },
    527: {
        name: "527 - Indianapolis, IN",
        code: "527",
    },
    528: {
        name: "528 - Miami-Ft. Lauderdale, FL",
        code: "528",
    },
    529: {
        name: "529 - Louisville, KY",
        code: "529",
    },
    530: {
        name: "530 - Tallahassee, FL-Thomasville, GA",
        code: "530",
    },
    531: {
        name: "531 - Tri-Cities, TN-VA",
        code: "531",
    },
    532: {
        name: "532 - Albany-Schenectady-Troy, NY",
        code: "532",
    },
    533: {
        name: "533 - Hartford & New Haven, CT",
        code: "533",
    },
    534: {
        name: "534 - Orlando-Daytona Beach, FL",
        code: "534",
    },
    535: {
        name: "535 - Columbus, OH",
        code: "535",
    },
    536: {
        name: "536 - Youngstown, OH",
        code: "536",
    },
    537: {
        name: "537 - Bangor, ME",
        code: "537",
    },
    538: {
        name: "538 - Rochester, NY",
        code: "538",
    },
    539: {
        name: "539 - Tampa-St Petersburg (Sarasota), FL",
        code: "539",
    },
    540: {
        name: "540 - Traverse City-Cadillac, MI",
        code: "540",
    },
    541: {
        name: "541 - Lexington, KY",
        code: "541",
    },
    542: {
        name: "542 - Dayton, OH",
        code: "542",
    },
    543: {
        name: "543 - Springfield-Holyoke, MA",
        code: "543",
    },
    544: {
        name: "544 - Norfolk-Portsmouth-Newport News,VA",
        code: "544",
    },
    545: {
        name: "545 - Greenville-New Bern-Washington, NC",
        code: "545",
    },
    546: {
        name: "546 - Columbia, SC",
        code: "546",
    },
    547: {
        name: "547 - Toledo, OH",
        code: "547",
    },
    548: {
        name: "548 - West Palm Beach-Ft. Pierce, FL",
        code: "548",
    },
    549: {
        name: "549 - Watertown, NY",
        code: "549",
    },
    550: {
        name: "550 - Wilmington, NC",
        code: "550",
    },
    551: {
        name: "551 - Lansing, MI",
        code: "551",
    },
    552: {
        name: "552 - Presque Isle, ME",
        code: "552",
    },
    553: {
        name: "553 - Marquette, MI",
        code: "553",
    },
    554: {
        name: "554 - Wheeling, WV-Steubenville, OH",
        code: "554",
    },
    555: {
        name: "555 - Syracuse, NY",
        code: "555",
    },
    556: {
        name: "556 - Richmond-Petersburg, VA",
        code: "556",
    },
    557: {
        name: "557 - Knoxville, TN",
        code: "557",
    },
    558: {
        name: "558 - Lima, OH",
        code: "558",
    },
    559: {
        name: "559 - Bluefield-Beckley-Oak Hill, WV",
        code: "559",
    },
    560: {
        name: "560 - Raleigh-Durham (Fayetteville), NC",
        code: "560",
    },
    561: {
        name: "561 - Jacksonville, FL",
        code: "561",
    },
    563: {
        name: "563 - Grand Rapids-Kalamazoo, MI",
        code: "563",
    },
    564: {
        name: "564 - Charleston-Huntington, WV",
        code: "564",
    },
    565: {
        name: "565 - Elmira, NY",
        code: "565",
    },
    566: {
        name: "566 - Harrisburg-Lancaster-York, PA",
        code: "566",
    },
    567: {
        name: "567 - Greenville-Spartanburg, SC",
        code: "567",
    },
    569: {
        name: "569 - Harrisonburg, VA",
        code: "569",
    },
    570: {
        name: "570 - Florence-Myrtle Beach, SC",
        code: "570",
    },
    571: {
        name: "571 - Ft. Myers-Naples, FL",
        code: "571",
    },
    573: {
        name: "573 - Roanoke-Lynchburg, VA",
        code: "573",
    },
    574: {
        name: "574 - Johnstown-Altoona, PA",
        code: "574",
    },
    575: {
        name: "575 - Chattanooga, TN",
        code: "575",
    },
    576: {
        name: "576 - Salisbury, MD",
        code: "576",
    },
    577: {
        name: "577 - Wilkes Barre-Scranton, PA",
        code: "577",
    },
    581: {
        name: "581 - Terre Haute, IN",
        code: "581",
    },
    582: {
        name: "582 - Lafayette, IN",
        code: "582",
    },
    583: {
        name: "583 - Alpena, MI",
        code: "583",
    },
    584: {
        name: "584 - Charlottesville, VA",
        code: "584",
    },
    588: {
        name: "588 - South Bend-Elkhart, IN",
        code: "588",
    },
    592: {
        name: "592 - Gainesville, FL",
        code: "592",
    },
    596: {
        name: "596 - Zanesville, OH",
        code: "596",
    },
    597: {
        name: "597 - Parkersburg, WV",
        code: "597",
    },
    598: {
        name: "598 - Clarksburg-Weston, WV",
        code: "598",
    },
    600: {
        name: "600 - Corpus Christi, TX",
        code: "600",
    },
    602: {
        name: "602 - Chicago, IL",
        code: "602",
    },
    603: {
        name: "603 - Joplin, MO-Pittsburg, KS",
        code: "603",
    },
    604: {
        name: "604 - Columbia-Jefferson City, MO",
        code: "604",
    },
    605: {
        name: "605 - Topeka, KS",
        code: "605",
    },
    606: {
        name: "606 - Dothan, AL",
        code: "606",
    },
    609: {
        name: "609 - St. Louis, MO",
        code: "609",
    },
    610: {
        name: "610 - Rockford, IL",
        code: "610",
    },
    611: {
        name: "611 - Rochester-Austin, MN-Mason City, IA",
        code: "611",
    },
    612: {
        name: "612 - Shreveport, LA",
        code: "612",
    },
    613: {
        name: "613 - Minneapolis-St. Paul, MN",
        code: "613",
    },
    616: {
        name: "616 - Kansas City, MO",
        code: "616",
    },
    617: {
        name: "617 - Milwaukee, WI",
        code: "617",
    },
    618: {
        name: "618 - Houston, TX",
        code: "618",
    },
    619: {
        name: "619 - Springfield, MO",
        code: "619",
    },
    622: {
        name: "622 - New Orleans, LA",
        code: "622",
    },
    623: {
        name: "623 - Dallas-Ft. Worth, TX",
        code: "623",
    },
    624: {
        name: "624 - Sioux City, IA",
        code: "624",
    },
    625: {
        name: "625 - Waco-Temple-Bryan, TX",
        code: "625",
    },
    626: {
        name: "626 - Victoria, TX",
        code: "626",
    },
    627: {
        name: "627 - Wichita Falls, TX & Lawton, OK",
        code: "627",
    },
    628: {
        name: "628 - Monroe, LA-El Dorado, AR",
        code: "628",
    },
    630: {
        name: "630 - Birmingham, AL",
        code: "630",
    },
    631: {
        name: "631 - Ottumwa, IA-Kirksville, MO",
        code: "631",
    },
    632: {
        name: "632 - Paducah, KY-Harrisburg, IL",
        code: "632",
    },
    633: {
        name: "633 - Odessa-Midland, TX",
        code: "633",
    },
    634: {
        name: "634 - Amarillo, TX",
        code: "634",
    },
    635: {
        name: "635 - Austin, TX",
        code: "635",
    },
    636: {
        name: "636 - Harlingen-Weslaco-McAllen, TX",
        code: "636",
    },
    637: {
        name: "637 - Cedar Rapids-Waterloo-Iowa City, IA",
        code: "637",
    },
    638: {
        name: "638 - St. Joseph, MO",
        code: "638",
    },
    639: {
        name: "639 - Jackson, TN",
        code: "639",
    },
    640: {
        name: "640 - Memphis, TN",
        code: "640",
    },
    641: {
        name: "641 - San Antonio, TX",
        code: "641",
    },
    642: {
        name: "642 - Lafayette, LA",
        code: "642",
    },
    643: {
        name: "643 - Lake Charles, LA",
        code: "643",
    },
    644: {
        name: "644 - Alexandria, LA",
        code: "644",
    },
    647: {
        name: "647 - Greenwood-Greenville, MS",
        code: "647",
    },
    648: {
        name: "648 - Champaign & Springfield-Decatur,IL",
        code: "648",
    },
    649: {
        name: "649 - Evansville, IN",
        code: "649",
    },
    650: {
        name: "650 - Oklahoma City, OK",
        code: "650",
    },
    651: {
        name: "651 - Lubbock, TX",
        code: "651",
    },
    652: {
        name: "652 - Omaha, NE",
        code: "652",
    },
    656: {
        name: "656 - Panama City, FL",
        code: "656",
    },
    657: {
        name: "657 - Sherman, TX-Ada, OK",
        code: "657",
    },
    658: {
        name: "658 - Green Bay-Appleton, WI",
        code: "658",
    },
    659: {
        name: "659 - Nashville, TN",
        code: "659",
    },
    661: {
        name: "661 - San Angelo, TX",
        code: "661",
    },
    662: {
        name: "662 - Abilene-Sweetwater, TX",
        code: "662",
    },
    669: {
        name: "669 - Madison, WI",
        code: "669",
    },
    670: {
        name: "670 - Ft Smith-Springdale, AR",
        code: "670",
    },
    671: {
        name: "671 - Tulsa, OK",
        code: "671",
    },
    673: {
        name: "673 - Columbus-Tupelo-West Point, MS",
        code: "673",
    },
    675: {
        name: "675 - Peoria-Bloomington, IL",
        code: "675",
    },
    676: {
        name: "676 - Duluth, MN-Superior, WI",
        code: "676",
    },
    678: {
        name: "678 - Wichita-Hutchinson, KS",
        code: "678",
    },
    679: {
        name: "679 - Des Moines-Ames, IA",
        code: "679",
    },
    682: {
        name: "682 - Davenport,IA-Rock Island-Moline,IL",
        code: "682",
    },
    686: {
        name: "686 - Mobile, AL-Pensacola, FL",
        code: "686",
    },
    687: {
        name: "687 - Minot-Bismarck-Dickinson, ND",
        code: "687",
    },
    691: {
        name: "691 - Huntsville-Decatur (Florence), AL",
        code: "691",
    },
    692: {
        name: "692 - Beaumont-Port Arthur, TX",
        code: "692",
    },
    693: {
        name: "693 - Little Rock-Pine Bluff, AR",
        code: "693",
    },
    698: {
        name: "698 - Montgomery (Selma), AL",
        code: "698",
    },
    702: {
        name: "702 - La Crosse-Eau Claire, WI",
        code: "702",
    },
    705: {
        name: "705 - Wausau-Rhinelander, WI",
        code: "705",
    },
    709: {
        name: "709 - Tyler-Longview(Nacogdoches), TX",
        code: "709",
    },
    710: {
        name: "710 - Hattiesburg-Laurel, MS",
        code: "710",
    },
    711: {
        name: "711 - Meridian, MS",
        code: "711",
    },
    716: {
        name: "716 - Baton Rouge, LA",
        code: "716",
    },
    717: {
        name: "717 - Quincy, IL-Hannibal, MO-Keokuk, IA",
        code: "717",
    },
    718: {
        name: "718 - Jackson, MS",
        code: "718",
    },
    722: {
        name: "722 - Lincoln & Hastings-Kearney, NE",
        code: "722",
    },
    724: {
        name: "724 - Fargo-Valley City, ND",
        code: "724",
    },
    725: {
        name: "725 - Sioux Falls(Mitchell), SD",
        code: "725",
    },
    734: {
        name: "734 - Jonesboro, AR",
        code: "734",
    },
    736: {
        name: "736 - Bowling Green, KY",
        code: "736",
    },
    737: {
        name: "737 - Mankato, MN",
        code: "737",
    },
    740: {
        name: "740 - North Platte, NE",
        code: "740",
    },
    743: {
        name: "743 - Anchorage, AK",
        code: "743",
    },
    744: {
        name: "744 - Honolulu, HI",
        code: "744",
    },
    745: {
        name: "745 - Fairbanks, AK",
        code: "745",
    },
    746: {
        name: "746 - Biloxi-Gulfport, MS",
        code: "746",
    },
    747: {
        name: "747 - Juneau, AK",
        code: "747",
    },
    749: {
        name: "749 - Laredo, TX",
        code: "749",
    },
    751: {
        name: "751 - Denver, CO",
        code: "751",
    },
    752: {
        name: "752 - Colorado Springs-Pueblo, CO",
        code: "752",
    },
    753: {
        name: "753 - Phoenix, AZ",
        code: "753",
    },
    754: {
        name: "754 - Butte-Bozeman, MT",
        code: "754",
    },
    755: {
        name: "755 - Great Falls, MT",
        code: "755",
    },
    756: {
        name: "756 - Billings, MT",
        code: "756",
    },
    757: {
        name: "757 - Boise, ID",
        code: "757",
    },
    758: {
        name: "758 - Idaho Falls-Pocatello, ID",
        code: "758",
    },
    759: {
        name: "759 - Cheyenne, WY-Scottsbluff, NE",
        code: "759",
    },
    760: {
        name: "760 - Twin Falls, ID",
        code: "760",
    },
    762: {
        name: "762 - Missoula, MT",
        code: "762",
    },
    764: {
        name: "764 - Rapid City, SD",
        code: "764",
    },
    765: {
        name: "765 - El Paso, TX",
        code: "765",
    },
    766: {
        name: "766 - Helena, MT",
        code: "766",
    },
    767: {
        name: "767 - Casper-Riverton, WY",
        code: "767",
    },
    770: {
        name: "770 - Salt Lake City, UT",
        code: "770",
    },
    771: {
        name: "771 - Yuma, AZ-El Centro, CA",
        code: "771",
    },
    773: {
        name: "773 - Grand Junction-Montrose, CO",
        code: "773",
    },
    789: {
        name: "789 - Tucson (Sierra Vista), AZ",
        code: "789",
    },
    790: {
        name: "790 - Albuquerque-Santa Fe, NM",
        code: "790",
    },
    798: {
        name: "798 - Glendive, MT",
        code: "798",
    },
    800: {
        name: "800 - Bakersfield, CA",
        code: "800",
    },
    801: {
        name: "801 - Eugene, OR",
        code: "801",
    },
    802: {
        name: "802 - Eureka, CA",
        code: "802",
    },
    803: {
        name: "803 - Los Angeles, CA",
        code: "803",
    },
    804: {
        name: "804 - Palm Springs, CA",
        code: "804",
    },
    807: {
        name: "807 - San Francisco-Oakland-San Jose, CA",
        code: "807",
    },
    810: {
        name: "810 - Yakima-Pasco-Richland-Kennewick, WA",
        code: "810",
    },
    811: {
        name: "811 - Reno, NV",
        code: "811",
    },
    813: {
        name: "813 - Medford-Klamath Falls, OR",
        code: "813",
    },
    819: {
        name: "819 - Seattle-Tacoma, WA",
        code: "819",
    },
    820: {
        name: "820 - Portland, OR",
        code: "820",
    },
    821: {
        name: "821 - Bend, OR",
        code: "821",
    },
    825: {
        name: "825 - San Diego, CA",
        code: "825",
    },
    828: {
        name: "828 - Monterey-Salinas, CA",
        code: "828",
    },
    839: {
        name: "839 - Las Vegas, NV",
        code: "839",
    },
    855: {
        name: "855 - Santa Barbara-San Luis Obispo, CA",
        code: "855",
    },
    862: {
        name: "862 - Sacramento-Stockton-Modesto, CA",
        code: "862",
    },
    866: {
        name: "866 - Fresno-Visalia, CA",
        code: "866",
    },
    868: {
        name: "868 - Chico-Redding, CA",
        code: "868",
    },
    881: {
        name: "881 - Spokane, WA",
        code: "881",
    },
});
