import { createEnum } from "../utils/enum-util";

export const DragItemType = createEnum({
    AD: {
        name: "Ad",
    },
    MARKETPLACE: {
        name: "Marketplace",
    },
    QUICK_FILTER: {
        name: "Quick Filter",
    },
    REPORT_KEYS: {
        name: "Report Keys",
    },
    REPORT_METRICS: {
        name: "Report Metrics",
    },
});
