import {Input, Button, Paragraph, useToast, Spinner} from "@beachfront/ui";
import { LockOutlined } from "@beachfront/ui/icons";
import { Form, Field, SubmitError } from "@beachfront/ui/forms";
import { useDocumentTitle } from "@beachfront/ui/hooks";
import { useParams, useLocation, useNavigate, Navigate } from "react-router-dom";

import { api } from "../../api";
import { authService } from "../../auth";
import PublicLayout from "../../components/public-layout";

function ResetPasswordPage() {
    const { token } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const toast = useToast();

    useDocumentTitle("Reset Password");

    const validate = (values) => {
        const errors = {};

        if (!values.password) {
            errors.password = "Please enter a new password.";
        }

        if (values.password !== values.confirm_password) {
            errors.confirm_password = "Passwords do not match.";
        }

        if (!values.confirm_password) {
            errors.confirm_password = "Please confirm your password.";
        }

        return errors;
    };

    const onSubmit = ({ password }) => {
        return api.resetPassword({ token, password }).then(
            (response) => {
                const user = { password, username: response.data.username };
                toast.success({
                    title: "Password updated.",
                    duration: 4000,
                    onClose: () => navigate("/login", { state: { user } }),
                });
            },
            (error) => {
                if (
                    error.response?.status === 400 &&
                    error.response?.data?.data?.validation_errors
                ) {
                    toast.error({ title: error.response.data.data.validation_errors });
                } else {
                    toast.error({ title: error.response?.data?.msg ?? error.message });
                }
            },
        );
    };

    const authResult = authService.isAuthenticated();
    if (authResult instanceof Promise) {
        return <Spinner />;
    }

    if (authResult) {
        const referrer = location.state?.referrer?.pathname ?? "/overview";
        return <Navigate to={referrer} replace />;
    }

    return (
        <PublicLayout title="Reset Password">
            <Form validate={validate} onSubmit={onSubmit}>
                {({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <SubmitError mb={3} />
                        <Paragraph>Reset your password below.</Paragraph>
                        <Field name="password">
                            <Input.Password
                                size="large"
                                placeholder="New Password"
                                autoComplete="new-password"
                                prefix={<LockOutlined />}
                                maxLength={255}
                            />
                        </Field>
                        <Field name="confirm_password">
                            <Input.Password
                                size="large"
                                placeholder="Confirm Password"
                                autoComplete="new-password"
                                prefix={<LockOutlined />}
                                maxLength={255}
                            />
                        </Field>
                        <Button
                            block
                            type="primary"
                            htmlType="submit"
                            size="large"
                            loading={submitting}
                        >
                            Reset
                        </Button>
                    </form>
                )}
            </Form>
        </PublicLayout>
    );
}

export default ResetPasswordPage;
