import { createEnum } from "../utils/enum-util";

export const InventoryFormat = createEnum({
    AD_TAGS: {
        name: "Ad Tags",
    },
    IN_FEED: {
        name: "In-Feed (SDK)",
        deprecated: true,
    },
    IN_STREAM: {
        name: "In-Stream (SDK)",
        deprecated: true,
    },
    MOBILE_OUTSTREAM: {
        name: "Mobile Outstream",
        deprecated: true,
    },
    OUTSTREAM: {
        name: "Outstream",
        deprecated: true,
    },
    PLAYER: {
        name: "BeachView Player",
        deprecated: true,
    },
    AD_POD: {
        name: "Ad Pod",
    },
});
