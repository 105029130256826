import { createEnum } from "../utils/enum-util";

export const HttpHeaderMacro = createEnum({
    IPADDRESS: {
        key: "[ipaddress]",
        name: "[ipaddress]",
    },
    USERAGENT: {
        key: "[useragent]",
        name: "[useragent]",
    },
    RTB_VERSION: {
        key: "[rtb-version]",
        name: "[rtb-version]",
    },
    CONTENTJSON: {
        key: "[contentjson]",
        name: "[contentjson]",
    },
    GZIP: {
        key: "[gzip]",
        name: "[gzip]",
    },
    IFA: {
        key: "[ifa]",
        name: "[ifa]",
    },
    IFA_TYPE: {
        key: "[ifa_type]",
        name: "[ifa_type]",
    },
    IFA_LMT: {
        key: "[ifa_lmt]",
        name: "[ifa_lmt]",
    },
});
