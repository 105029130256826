import { createEnum } from "../utils/enum-util";

export const TrafficSync = createEnum({
    ANY: {
        name: "All Users",
    },
    IN_SYNC: {
        name: "In Sync",
    },
    IN_SYNC_NO_DNT: {
        name: "In Sync (exlcude limited tracking)",
    },
});
