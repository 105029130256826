import { createEnum } from "../utils/enum-util";

export const ScheduleType = createEnum({
    OFF: {
        key: "off",
        name: "Off",
    },
    DAILY: {
        key: "daily",
        name: "Daily",
    },
    WEEKLY: {
        key: "weekly",
        name: "Weekly",
    },
    MONTHLY: {
        key: "monthly",
        name: "Monthly",
    },
});
