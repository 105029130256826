import { useEffect } from "react";
import { Button, Heading, Text, Flex } from "@beachfront/ui";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";
import { parse } from "cookie";

import logoBlack from "../../assets/img/bf-logo-horizontal-black.png";
import logoWhite from "../../assets/img/bf-logo-horizontal-white.png";

function PageInfo() {
    const theme = useTheme();
    const backendInfo = JSON.parse(parse(document.cookie)?.be_info ?? "{}");

    const renderHtml = (value) => {
        if (value === undefined) {
            return "";
        }
        return <div dangerouslySetInnerHTML={{ __html: value.replace(/\+/gm, " ") }}></div>;
    };


    useEffect(() => {
        window.addEventListener("pageshow", (e) => {
            // force reload previous page when backward button clicked
            if (
                e.persisted ||
                window.performance.getEntriesByType("navigation")[0].type === "back_forward"
            ) {
                window.location.reload();
            }
        });
        window.addEventListener("unload", function () {});
        window.addEventListener("beforeunload", function () {});
    }, []);

    return (
        <Flex
            gap={3}
            minHeight={300}
            height="calc(100vh - 40px)"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
        >
            <Link to="/">
                <img
                    alt="Beachfront Media"
                    src={theme.name === "light" ? logoBlack : logoWhite}
                    width={320}
                />
            </Link>
            <Heading level={1}>Information</Heading>
            <Text>{renderHtml(backendInfo?.message ?? "Something went wrong.")}</Text>
            <Button type="primary" href={backendInfo?.redirect ?? "/"}>
                Continue
            </Button>
        </Flex>
    );
}

export default PageInfo;
