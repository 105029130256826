import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { Menu, Dropdown, Avatar, Badge, Button, Flex, Text, Tooltip } from "@beachfront/ui";
import { MailOutlined, MoonOutlined, SunOutlined } from "@beachfront/ui/icons";
import { useLocalStorage, useBreakpoints } from "@beachfront/ui/hooks";

import { useCurrentUser, useUnreadMessageCount } from "../../hooks";
import { CustomPermission } from "../../enums";
import Permission from "../permission";

const rotate = keyframes`
  0%, 100% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(20deg);
  }
`;

const ThemeButton = styled(Button)`
    &.ant-btn {
        font-size: 18px;
        color: ${(p) => p.theme.colors.dark.text};

        &:hover,
        &:active {
            color: ${(p) => p.theme.colors.dark.hover};
            background: ${(p) => p.theme.colors.alpha.white[2]};
        }
    }

    svg {
        animation: ${rotate} 0.3s linear;
    }
`;

const MessageButton = styled(Button)`
    &.ant-btn {
        font-size: 18px;
        padding: 0 8px;
        color: ${(p) => p.theme.colors.dark.text};

        &:hover,
        &:active {
            color: ${(p) => p.theme.colors.dark.hover};
            background: ${(p) => p.theme.colors.alpha.white[2]};
        }
    }
`;

const MessageBadge = styled(Badge)`
    sup {
        box-shadow: none !important;
    }
`;

function AppAccount() {
    const [user] = useCurrentUser();
    const navigate = useNavigate();
    const breakpoints = useBreakpoints();
    const unreadMessageCount = useUnreadMessageCount(
        Permission.hasAccess(user, CustomPermission.MESSAGES.key),
    );
    const [baseTheme, setBaseTheme] = useLocalStorage("base-theme", "light");

    const menu = (
        <Menu theme="dark">
            <Menu.Item eventKey="logout" onClick={() => navigate("/logout")}>
                Sign Out
            </Menu.Item>
        </Menu>
    );

    return (
        <Flex mr={3} gap={2} alignItems="center">
            <Tooltip
                title={baseTheme === "light" ? "Dark Mode" : "Light Mode"}
                trigger="hover"
                placement="bottom"
                getPopupContainer={(el) => el.parentNode}
            >
                <ThemeButton
                    type="text"
                    onClick={() => setBaseTheme(baseTheme === "light" ? "dark" : "light")}
                    icon={baseTheme === "light" ? <MoonOutlined /> : <SunOutlined />}
                />
            </Tooltip>
            <Permission require={CustomPermission.MESSAGES.key}>
                <Tooltip
                    title="Messages"
                    trigger="hover"
                    placement="bottom"
                    getPopupContainer={(el) => el.parentNode}
                >
                    <MessageButton type="text" onClick={() => navigate("/messages")}>
                        <Flex gap={1} alignItems="center">
                            <MailOutlined />
                            {unreadMessageCount.data ? (
                                <MessageBadge size="small" count={unreadMessageCount.data} />
                            ) : null}
                        </Flex>
                    </MessageButton>
                </Tooltip>
            </Permission>
            <Dropdown
                placement="bottomRight"
                trigger="click"
                overlay={menu}
                overlayStyle={{ minWidth: 0 }}
                getPopupContainer={(el) => el.parentNode}
            >
                <Flex ml={1} height="100%" alignItems="center" cursor="pointer">
                    <Avatar
                        shape="square"
                        bg="gray.5"
                        size="small"
                        src={user.imageUrl}
                        fallback={user.userName.charAt(0).toUpperCase()}
                    />
                    {breakpoints.md && (
                        <Text fontSize={0} ml={2} color="dark.text" ellipsis>
                            {user.userName}
                        </Text>
                    )}
                </Flex>
            </Dropdown>
        </Flex>
    );
}

export default AppAccount;
