import { createEnum } from "../utils/enum-util";

export const DefaultAuctionType = createEnum({
    FIRST_PRICE: {
        key: "1ST_PRICE",
        name: "1st Price",
    },
    SECOND_PRICE: {
        key: "2ND_PRICE",
        name: "2nd Price",
    },
});
