import { createEnum } from "../utils/enum-util";

export const MVPDFormat = createEnum({
    NONE: {
        name: "NONE",
    },
    FRONTIER: {
        name: "FRONTIER",
    },
    COMCAST: {
        name: "COMCAST",
    },
    SEACHANGE: {
        name: "SEACHANGE",
    },
    ARMSTRONG: {
        name: "ARMSTRONG",
    },
    CANOE: {
        name: "CANOE",
    },
    ARMSTRONG_NO_TR: {
        name: "ARMSTRONG_NO_TR",
    },
    SEACHANGE_AMX: {
        name: "SEACHANGE_AMX",
    },
    SEACHANGE_NO_TR: {
        name: "SEACHANGE_NO_TR",
    },
    SEACHANGE_MULTIMEDIOS: {
        name: "SEACHANGE_MULTIMEDIOS",
    },
    SEACHANGE_TVN_CHILE: {
        name: "SEACHANGE_TVN_CHILE",
    },
    SEACHANGE_TV_CABLE: {
        name: "SEACHANGE_TV_CABLE",
    },
    SEACHANGE_TELEMEDELLIN: {
        name: "SEACHANGE_TELEMEDELLIN",
    },
});
