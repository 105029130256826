import { createGlobalStyle } from "styled-components";
import { globalStyle } from "@beachfront/ui";

const GlobalStyle = createGlobalStyle`
    ${globalStyle}

    &::-webkit-scrollbar {
		width: 6px;
		height: 6px;
	}

	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar-corner {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background: ${(p) => p.theme.colors.alpha[5]};
		border: none;
		border-radius: 6px;
	}

    body {
		&::-webkit-scrollbar {
			background: ${(p) => p.theme.colors.dark.bg}
		}

		&::-webkit-scrollbar-track,
		&::-webkit-scrollbar-corner {
			background: ${(p) => p.theme.colors.border.split};
		}

		&::-webkit-scrollbar-track {
			margin-top: 40px;
		}
	}

    .ant-spin-nested-loading > div > .ant-spin {
        z-index: 1 !important;
    }

    .ant-table-row-expand-icon-spaced {
        visibility: hidden;
    }
`;

export default GlobalStyle;
