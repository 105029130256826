import { isObject } from "./type-util";

/**
 * Create a new enumeration based on a given specification.
 *
 * @param {Object} spec
 * @returns {{
 *     keys: (function(): Array),
 *     values: (function(): Array),
 *     includes: (function(*): Boolean),
 *     fromKey: (function(*): Object)
 * }}
 */
export function createEnum(spec) {
    if (!isObject(spec)) {
        throw Error("Enum argument must be an object");
    }

    const enumObject = {};

    Object.entries(spec).forEach(([name, props]) => {
        const key = Object.prototype.hasOwnProperty.call(props, "key") ? props.key : name;
        enumObject[name] = Object.freeze({
            key,
            ...props,
            toString: () => key.toString(),
        });
    });

    Object.defineProperties(enumObject, {
        keys: {
            value: () => Object.values(enumObject).map((e) => e.key),
            enumerable: false,
        },
        values: {
            value: () => Object.values(enumObject),
            enumerable: false,
        },
        includes: {
            value: (key) => !!Object.values(enumObject).find((e) => e.key === key),
            enumerable: false,
        },
        fromKey: {
            value: (key) => Object.values(enumObject).find((e) => e.key === key),
            enumerable: false,
        },
    });

    return enumObject;
}
