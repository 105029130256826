import { api } from "../api";
import { createEnum } from "../utils/enum-util";
import Permission from "../components/permission";

import { AccountType } from "./account-type";
import { CustomPermission } from "./custom-permission";

export const ChangelogSource = createEnum({
    INVENTORY: {
        name: "Inventory",
        link: "/inventory/:id/general",
        access: "inventory.manage_inventory.edit",
    },
    ADS: {
        name: "Ads",
        link: "/demand/ads/:id/general",
        access: "demand.ads.edit",
    },
    MARKETPLACES: {
        name: "Marketplaces",
        link: "/marketplaces/owned/:id/general",
        access: "marketplaces.owned.edit",
    },
    MEDIALISTS: {
        name: "Media Lists",
        link: "/demand/lists/media/:id",
        access: "demand.media_lists.edit",
    },
    LOCATIONLISTS: {
        name: "Location Lists",
        link: "/demand/lists/location/:id",
        access: "demand.location_lists.edit",
    },
    ADVERTISERLISTS: {
        name: "Advertiser Lists",
        link: "/demand/lists/advertiser/:id",
        access: "demand.advertiser_lists.edit",
    },
    CONTENTLISTS: {
        name: "Content Lists",
        link: "/demand/lists/content/:id",
        access: (user) =>
            Permission.hasAccess(user, "demand.media_lists.read") &&
            Permission.hasAccess(user, [AccountType.SUPER.key, CustomPermission.CREATIVE_ADI.key]),
    },
    ACCOUNTLISTS: {
        name: "Account Lists",
        link: "/demand/lists/account/:id",
        access: (user) =>
            Permission.hasAccess(user, "demand.media_lists.read") &&
            Permission.hasAccess(user, AccountType.SUPER.key),
    },
    ACCOUNT: {
        name: "Account",
        link: api.getAdminUrl() + "/admin/accounts/edit/:id",
        access: (user) =>
            Permission.hasAccess(user, AccountType.SUPER.key) &&
            Permission.hasAccess(user, "admin.dashboard.read") &&
            Permission.hasAccess(user, "admin.accounts.edit"),
    },
    USER: {
        name: "User",
        link: "/settings/users/:id/general",
        access: "users.manage_users.edit",
    },
    EXCHANGE: {
        name: "RTB Inventory",
        link: "/exchanges/:id/general",
        access: [AccountType.SUPER.key, AccountType.ENTERPRISE.key],
    },
    QUALITY_RULE: {
        name: "Quality Rule",
        link: "/quality/rules/:id/general",
        access: CustomPermission.CREATIVEREVIEW.key,
    },
    BUYERS: {
        name: "Buyer",
        link: "/demand/buyers/:id/general",
        access: (user) =>
            Permission.hasAccess(user, "demand.dsp.read") && !user.isOrganizationAdmin,
    },
    DSP: {
        name: "DSP",
        link: "/demand/dsp/:id/general",
        access: CustomPermission.DSP.key,
    },
    APPMAPPING: {
        name: "App Mapping",
        link: "/app-mapping/overview",
        access: "inventory.manage_inventory.read",
    },
    ASPROVIDER: {
        name: "Audience Segments Provider",
        link: api.getAdminUrl() + "/admin/settings/audienceprovider",
        access: (user) =>
            Permission.hasAccess(user, AccountType.SUPER.key) &&
            Permission.hasAccess(user, "admin.dashboard.read"),
    },
});
