import { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavMenu, Flex, Box, ScrollArea } from "@beachfront/ui";

import { CustomPermission } from "../../enums";
import { useCurrentUser } from "../../hooks";
import Permission from "../permission";

import appMenus from "./app-menus";
import AppBrand from "./app-brand";
import AppMenuFooter from "./app-menu-footer";

function AppSider() {
    const [user] = useCurrentUser();
    const location = useLocation();
    const container = useRef();

    const menuItems = buildMenuItems(appMenus, user);
    const selectedKeys = getSelectedKeys(location.pathname);

    const renderLink = ({ href, label }) => {
        return href?.startsWith("/") ? (
            <Link to={href}>{label}</Link>
        ) : (
            <a href={href} rel="noreferrer" target="_self">
                {label}
            </a>
        );
    };

    return (
        <Flex ref={container} flexDirection="column" height="100%">
            <AppBrand />
            <ScrollArea mt={2} flex="auto" overScroll="contain" variant="inverted">
                <Box py={2}>
                    <NavMenu
                        orientation="vertical"
                        bg="transparent"
                        variant="dark"
                        selectedKeys={selectedKeys}
                        items={menuItems}
                        renderLink={renderLink}
                        getPopupContainer={() => container.current}
                    />
                </Box>
            </ScrollArea>
            <AppMenuFooter />
        </Flex>
    );
}

function buildMenuItems(menus, user) {
    // TODO refactor: this prevents super account from seeing organization menu items
    const checkOrganizationAccess = (m) => {
        return m.access !== CustomPermission.ORGANIZATION.key || user.isOrganizationAdmin;
    };

    return menus
        .map((item) => {
            const hasAccess = !item.access || Permission.hasAccess(user, item.access);
            const visibleItems = item.children
                ? item.children
                      .filter(checkOrganizationAccess)
                      .filter((c) => !c.access || Permission.hasAccess(user, c.access))
                      .map((c) => ({
                          key: c.href,
                          href: c.href,
                          title: c.title,
                      }))
                : [];

            if (visibleItems.length === 0 && !item.href) {
                return null;
            }

            if (hasAccess) {
                const isSingle = visibleItems.length === 1;
                return {
                    key: isSingle ? visibleItems[0].href : (item.href ?? item.title),
                    title: item.title,
                    icon: item.icon,
                    href: isSingle ? visibleItems[0].href : item.href,
                    children: isSingle ? [] : visibleItems,
                };
            }

            return null;
        })
        .filter((x) => x);
}

function getSelectedKeys(pathname) {
    return pathname
        .split("/")
        .map((_, i, arr) => arr.slice(0, i + 1).join("/"))
        .filter((x) => x);
}

export default AppSider;
