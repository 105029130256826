import { Input, Button, Box, Text, Paragraph, useToast, Spinner } from "@beachfront/ui";
import { MailOutlined } from "@beachfront/ui/icons";
import { Form, Field } from "@beachfront/ui/forms";
import { useDocumentTitle } from "@beachfront/ui/hooks";
import { Navigate, Link, useLocation, useNavigate } from "react-router-dom";

import { api } from "../../api";
import { authService } from "../../auth";
import PublicLayout from "../../components/public-layout";

function ForgotPasswordPage() {
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();

    useDocumentTitle("Forgot Password");

    const validate = (values) => {
        const errors = {};

        if (!values.email) {
            errors.email = "Required";
        }

        return errors;
    };

    const onSubmit = (values) => {
        return api.forgotPassword({ email: values.email }).then(
            () => {
                toast.success({
                    title: "An email with password reset instructions has been sent to your email address.",
                    duration: 4000,
                    onClose: () => navigate("/login"),
                });
            },
            (error) => {
                if (
                    error.response?.status === 400 &&
                    error.response?.data?.data?.validation_errors
                ) {
                    toast.error({ title: error.response.data.data.validation_errors });
                } else {
                    toast.error({ title: error.response?.data?.msg ?? error.message });
                }
            },
        );
    };

    const authResult = authService.isAuthenticated();
    if (authResult instanceof Promise) {
        return <Spinner />;
    }

    if (authResult) {
        const referrer = location.state?.referrer?.pathname ?? "/overview";
        return <Navigate to={referrer} replace />;
    }

    return (
        <PublicLayout title="Forgot Password">
            <Form validate={validate} onSubmit={onSubmit}>
                {({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <Paragraph>
                            Enter your email address and we&apos;ll send you a link to reset your
                            password.
                        </Paragraph>
                        <Field name="email">
                            <Input size="large" placeholder="Email" prefix={<MailOutlined />} />
                        </Field>
                        <Button
                            block
                            type="primary"
                            htmlType="submit"
                            size="large"
                            loading={submitting}
                        >
                            Send
                        </Button>
                        <Box pt={3}>
                            <Text fontSize={0}>
                                <Link to="/login">Back to login</Link>
                            </Text>
                        </Box>
                    </form>
                )}
            </Form>
        </PublicLayout>
    );
}

export default ForgotPasswordPage;
