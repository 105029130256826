import { api } from "../api";

export const USER_STORAGE_KEY = "user";
export const SSO_ENABLED_STORAGE_KEY = "sso_enabled";

export const authService = {
    isAuthenticated() {
        const user = localStorage.getItem(USER_STORAGE_KEY);
        try {
            // return true or raise exception
            return !!JSON.parse(user);
        } catch {
            if (user === "undefined") {
                // return Promise
                return api.check().then((response) => {
                    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(response.data.user_data));
                    localStorage.setItem(SSO_ENABLED_STORAGE_KEY, response.data.sso_enabled);
                    window.location.reload(false);
                });
            } else {
                return false;
            }
        }
    },

    login({ username, password, remember }) {
        return api.login({ username, password, remember }).then((response) => {
            localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(response.data.user_data));
            localStorage.setItem(SSO_ENABLED_STORAGE_KEY, response.data.sso_enabled);
            return response;
        });
    },

    logout() {
        const user = localStorage.getItem(USER_STORAGE_KEY);
        if (user !== "undefined") {
            return api.logout().then((response) => {
                localStorage.removeItem(USER_STORAGE_KEY);
                sessionStorage.clear();
                if (response.data.redirect_url) {
                    window.location.href = response.data.redirect_url;
                } else {
                    window.location.reload(false);
                }
            });
        } else {
            return true;
        }
    },
};
