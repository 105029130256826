import { createEnum } from "../utils/enum-util";

export const DealType = createEnum({
    FIXED: {
        name: "Deal Fixed",
    },
    FIXED_PG: {
        name: "Deal Guarantee",
    },
    FIRST: {
        name: "Deal 1st Price",
    },
    AUCTION: {
        name: "Deal Auction",
    },
    4: {
        name: "RTB VAST",
    },
    9: {
        name: "RTB VPAID",
    },
});
