import { createEnum } from "../utils/enum-util";

export const CreativeReviewAction = createEnum({
    APPROVE: {
        name: "Approve",
    },
    BLOCK: {
        name: "Block",
    },
});
