import { Input, Checkbox, Button, Flex, Box, Text, Spinner } from "@beachfront/ui";
import { UserOutlined, LockOutlined } from "@beachfront/ui/icons";
import { Form, Field, SubmitError, FORM_ERROR } from "@beachfront/ui/forms";
import { useDocumentTitle } from "@beachfront/ui/hooks";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";

import { api } from "../../api";
import { authService } from "../../auth";
import { USER_STORAGE_KEY, SSO_ENABLED_STORAGE_KEY } from "../../auth/auth-service";
import { resolveProp } from "../../utils/object-util";
import PublicLayout from "../../components/public-layout";

function LoginPage() {
    const navigate = useNavigate();
    const location = useLocation();

    useDocumentTitle("Login", { restoreOnUnmount: false });

    const initialValues = {
        username: resolveProp(location, "state.user.username"),
        password: resolveProp(location, "state.user.password"),
        remember: false,
    };

    const validate = (values) => {
        const errors = {};

        if (!values.username) {
            errors.username = "Required";
        }

        if (!values.password) {
            errors.password = "Required";
        }

        return errors;
    };

    const onSubmit = (values) => {
        return authService.login(values).then(
            () => {
                const pathname = resolveProp(location, "state.referrer.pathname", "/overview");
                navigate(pathname, { replace: true });
            },
            () => {
                return { [FORM_ERROR]: "Login failed. Please try again." };
            },
        );
    };

    const authResult = authService.isAuthenticated();
    if (authResult instanceof Promise) {
        return <Spinner />;
    }

    const referrer = location.state?.referrer?.pathname ?? "/overview";
    if (authResult) {
        return <Navigate to={referrer} replace />;
    }

    const sso_enabled = localStorage.getItem(SSO_ENABLED_STORAGE_KEY) === "true";
    if (sso_enabled) {
        setTimeout(() => {
            localStorage.removeItem(USER_STORAGE_KEY);
            window.location.href = api.getSSORedirectUrl() + `?redirect_uri=${referrer}`;
        }, 0);

        return <Spinner />;
    }

    return (
        <PublicLayout title="Login">
            <Form initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
                {({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <SubmitError mb={3} />
                        <Field name="username">
                            <Input
                                size="large"
                                placeholder="Email address or Username"
                                prefix={<UserOutlined />}
                            />
                        </Field>
                        <Field name="password">
                            <Input.Password
                                size="large"
                                placeholder="Password"
                                prefix={<LockOutlined />}
                            />
                        </Field>
                        <Box mb={-3}>
                            <Field name="remember">
                                <Checkbox>Remember me</Checkbox>
                            </Field>
                        </Box>
                        <Button
                            block
                            type="primary"
                            htmlType="submit"
                            size="large"
                            loading={submitting}
                        >
                            Login
                        </Button>
                        <Flex pt={3}>
                            <Box m="auto" />
                            <Text fontSize={0}>
                                <Link to="/forgot-password">Forgot password?</Link>
                            </Text>
                        </Flex>
                    </form>
                )}
            </Form>
        </PublicLayout>
    );
}

export default LoginPage;
