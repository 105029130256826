import { createEnum } from "../utils/enum-util";

export const CreativeReviewReason = createEnum({
    SEAT: {
        name: "Seat",
        key: 1,
    },
    IAB_CATEGORY: {
        name: "IAB Category",
        key: 2,
    },
    AD_DOMAIN: {
        name: "Ad Domain",
        key: 3,
    },
    CREATIVE: {
        name: "Creative",
        key: 4,
    },
});
